$splitter-space: 0 15px 0 20px;

@mixin fill-height {
    height: 100%;
}

.administration-page {
    @include fill-height();

    .k-splitter {
        @include fill-height();
        border-style: none;
    }

    .administration-page-header {
        font-size: 24px;
    }

    &__right-side,
    &__left-side {
        @include fill-height();

        &__title-bar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__left-side {
        display: flex;
        flex-flow: column;
        align-items: stretch;        
        margin: $splitter-space;
    }

    &__right-side {
        margin: $splitter-space;
    }    

    &__button-container,
    &__configuration-property {
        width: 100%;
        position: relative;
        display: inline-block;
        margin: 10px 0 10px 0;

        span.k-textbox-container {
            width: 50%;
        }
    }

    &__button-container {
        display: inline-flex;

        &.to-left {
            justify-content: flex-end;
        }
    }

    &__delete-button {
        background: #f43a30;
        color: white;

        &:hover,
        &:active {
            background: #c2130a;
            color: white;
        }
    }

    &__button-hidden {
        visibility: hidden;
    }
}
