$z-index-popup: 300;
$top-bar-height: 50px;

@mixin transition($value) {
    -webkit-transition: $value;
    -moz-transition: $value;
    -o-transition: $value;
    transition: $value;
}

.sv-sliding-modal {
    position: fixed;
    top: $top-bar-height;
    height: 100vh;
    background: transparent;
    z-index: calc(#{$z-index-popup} + 1);

    .sv-sliding-modal__dialog {
        height: 100%;
        width: 100%;
        position: relative;
        background-color: white;
    }

    &--left {
        left: 0px;

        .sv-sliding-modal__dialog {
            border-right: 1px solid #797979;
            box-shadow: 2px 0 4px 0px rgba(0, 0, 0, 0.5);
        }

        &-enter > .sv-sliding-modal__dialog {
            left: -100%;
        }
        &-enter-active > .sv-sliding-modal__dialog {
            left: 0px;
            @include transition(left 300ms);
        }
        &-exit > .sv-sliding-modal__dialog {
            left: 0px;
        }
        &-exit-active > .sv-sliding-modal__dialog {
            left: -100%;
            @include transition(left 300ms);
        }
    }

    &--right {
        right: 0px;

        .sv-sliding-modal__dialog {
            border-left: 1px solid #797979;
        }

        &-enter > .sv-sliding-modal__dialog {
            right: -100%;
        }
        &-enter-active > .sv-sliding-modal__dialog {
            right: 0px;
            @include transition(right 300ms);
        }
        &-exit > .sv-sliding-modal__dialog {
            right: 0px;
        }
        &-exit-active > .sv-sliding-modal__dialog {
            right: -100%;
            @include transition(right 300ms);
        }
    }
}

.sv-sliding-modal__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;

    &-enter {
        opacity: 0;
    }
    &-enter-active {
        opacity: 1;
        @include transition(opacity 300ms);
    }
    &-exit {
        opacity: 1;
    }
    &-exit-active {
        opacity: 0;
        @include transition(opacity 300ms);
    }
}

.sv-sliding-modal-close-btn {
    width: 24px;
    height: 24px;
    background: none;
    color: inherit;
    border: none;
    cursor: pointer;
    padding: 0px;
}

.sv-sliding-modal-close-btn-wrapper {
    text-align: right;
}