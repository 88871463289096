.adm-tab-parent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}


.adm-tabcontent-children {
    display: flex;
    flex-grow: 1;
    overflow: auto;
}

.adm-tabcontent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;


    .adm-tabcontent-iframe {
        height: 100%;
        width: 100%;
        flex-grow: 1;
        border: none;
        margin: 0;
        padding: 0;
    }
}

#administration {
    height: 100%;
    position: relative;
    box-sizing: border-box;
}

#administration .configuration-label {
    overflow: hidden
}

#administration .configuration-control,
#administration .configuration-control-large,
#administration .configuration-translation-control {
    margin-bottom: 10px
}

#administration .configuration-button {
    margin-bottom: 10px;
    margin-top: 25px
}

#administration .configuration-control .edit-component,
#administration .configuration-control select {
    max-width: 206px;
}

#administration .configuration-control-large .edit-component,
#administration .configuration-control-large select {
    max-width: none;
}

#administration .document-search-input .configuration-control .edit-component {
    max-width: 100%;
}

#administration h3 {
    margin-top: 0;
    line-height: 27px;
}

#administration>div,
#administration>div>article {
    position: relative;
    height: 100%;
}

#administration #administration-sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 0;
    z-index: 999;
}

#administration #administration-sidebar #additional-buttons {
    margin-top: 0px;
    margin-bottom: 0px;
}

#administration #administration-sidebar #additional-buttons .btn {
    margin-right: 10px;
}

#administration #administration-sidebar nav {
    position: relative;
}

#administration #administration-sidebar nav button#toggle-sidebar-visibility {
    position: absolute;
    top: 0;
    right: 0;
}

#administration #administration-sidebar>div>section {
    width: 450px;
}


#administration .dataGrid td {
    cursor: pointer;
}

#administration .action-buttons {
    min-height: 48px;
}

#administration nav.action-buttons {
    margin-bottom: 15px;
    min-height: initial;
}

#administration .action-buttons button {
    margin-right: 5px;
}

#administration #administration-entity-editor>div.row-fluid {
    position: relative;
    height: 100%;
}

#administration #administration-entity-editor td.center {
    text-align: center;
}

#administration #administration-entity-editor td.middle {
    vertical-align: middle;
}

#administration #administration-entity-editor .autocompleter .has-value {
    padding-top: 5px;
}

#administration #administration-entity-editor .autocompleter .has-value span.remove {
    cursor: pointer;
    margin-right: 5px;
}

#administration #administration-entity-editor .medius-grid {
    margin: 5px 0;
}

#administration #administration-entity-editor .readonly-box .medius-grid {
    margin: 0;
}


#administration #administration-object-browser-ctn {
    height: 100%;
    padding-right: 5px;
}

#administration .dropdown-menu>li .disabled {
    color: grey;
    pointer-events: none !important;
    cursor: default;
}

#administration #administration-grid-section {
    height: 100%;
    padding-top: 10px;
    padding-left: 20px;
    background-color: var(--md-color-page-background-empty);
}

#administration #administration-container {
    padding-right: 0px;
    padding-left: 0px;
}

#administration #administration-item-section {
    height: 100%;
    padding-top: 10px;
    padding-right: 20px;
}

#administration #administration-object-browser-ctn>section aside {
    margin: 10px 0 10px;
}

#administration #administration-object-browser-ctn>section #filter-link-container {
    line-height: 32px;
}

#administration .append-select {
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
    width: 150px;
}

#administration .roles-preview {
    margin-top: 5px;
}

.bulk-admin-editor input[type=checkbox],
.bulk-admin-editor label {
    margin-bottom: 5px;
    display: inline-block;
}

#user-bulk-editor .configuration-control {
    width: 220px;
    margin-bottom: 10px;
}

.actionableEmailsContainer {
    margin: 5px 0 15px;
}

.actionableEmailsStatus {
    margin: 2px;
}

.wizard .row-margin-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.wizard .wizard-bulk-updates-info {
    margin-bottom: 15px;
}

.bulkDeleteWindow,
.bulkNotificationWindow {
    display: block;
    position: fixed;
    top: 0;
}

#bulk-administration-tabs-content .bulk-editor {
    min-height: 200px;
}

.medius-grid .grid-row>.grid-cell select.reminders-reminder-type {
    width: calc(100% - 18px);
}

#administration .medius-grid .grid>article .grid-row.new-row>.grid-cell {
    background-color: #E0FFCC;
}

.medius-grid .reminders-reminder-details {
    line-height: 25px;
}

#administration .configuration-tabs section header {
    margin-bottom: 10px;
    font-size: 20px;
    border-bottom: 1px solid #ddd;
}

#administration .configuration-tabs section.subsection header {
    font-size: 18px;
    border-bottom: none;
    margin-bottom: 0;
}

#administration .configuration-tabs .configuration-property-control {
    margin-bottom: 20px;
}

#administration .configuration-tabs .configuration-property-control .editors-bool {
    margin-right: 0;
}


#administration .metadata-upload {
    margin-top: 50px;
}

#administration .metadata-upload .drag-and-drop {
    width: 100%;
    border: #dddddd dashed 5px;
    height: 100px;
    text-align: center;
    color: #dddddd;
    padding-top: 20px;
    font-size: 30px;
    font-weight: bold;
}

#administration .metadata-upload .drag-and-drop.active {
    border-color: #006dcc;
    color: #006dcc;
}

#administration .metadata-upload .manual textarea {
    height: 400px;
}

#administration td input {
    margin-bottom: 0;
}

#administration .local-user-password .section-header {
    margin-bottom: 10px;
    margin-top: 25px;
    font-size: 20px;
    border-bottom: 1px solid #ddd;
}

#administration .local-user-password .section-info {
    color: #999;
    margin-bottom: 10px;
    font-size: 13px;
}

#administration .local-user-password .force-change {
    margin-bottom: 10px;
    font-weight: bold;
}

#administration .local-user-password .login {
    font-weight: bold;
}

#administration .local-user-password .new-password {
    font-weight: bold;
    margin-bottom: 5px;
}

#administration .local-user-password input[type="checkbox"] {
    margin: 0;
}

#administration .tab-pane .overflow-without-scrollbar {
    overflow: hidden;
}

#administration .unlicensed {
    opacity: 0.6;
}

#administration .unlicensed * {
    pointer-events: none;
}