.user-account-notfound-page {
    overflow-wrap: break-word;

    &__message {
        margin-bottom: 5px;
        line-height: 1.2;
    }

    &__user-not-found-identity {
        padding: 10px;
        color: red;
    }
}