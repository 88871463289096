.limitContainer {
    .k-numerictextbox > .k-state-disabled {
        background: #dddddd;
        opacity: 0.3;
    }
}

.limitContainer {
    position: relative;
    margin: 10px;
}

.limitTooltip {
    border-radius: 6px;
    width: 160px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;

    .tooltipText::after {
        content: " ";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent black transparent;
    }
}