.user-superTabs-container {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 1% 0;
}

.user-superTabs-children {
    padding: 0 0.5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.user-superTabs-userName {
    font-size: 120%;
    padding-left: 3px;
}