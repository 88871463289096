.resolutionConfiguration {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__section-title {
        color: #666666;
        border-bottom: #666666 1px solid;
        margin-bottom: 10px;
        font-size: 20px;
    }

    &__save-button {
        margin-left: 10px;
    }
}
