#sox .configuration-label {
    overflow: hidden
}

#sox .configuration-control {
    margin-bottom: 10px
}

#sox .sox-report-item-menu div.sox-report-item .item-operator a,
#sox .sox-report-item-menu div.sox-report-item .item-operator span {
    display: none;
}

#sox .sox-report-item-menu div.sox-report-item:hover .item-operator a,
#sox .sox-report-item-menu div.sox-report-item:hover .item-operator span {
    display: block;
}

#sox .sox-report-item-menu div.sox-report-item:hover .item-operator div {
    cursor: pointer;
}

#sox .sox-report-item-menu div.sox-report-item .item-operator {
    margin-right: 3px;
    float: right;
}

#sox div.report-author-item .item-operator {
    cursor: pointer;
    display: inline-block;
}

#sox div.report-author-item .sox-report-element {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

#sox .report-items-menu div.report-author-item .item-operator {
    margin-right: 3px;
    float: right;
}


#sox .report-author-item {
    margin: 5px;
}

#sox .action-buttons .btn{
    margin-left: 5px;
}

#sox aside.saved-reports-side-menu {
    background-color: #F5F5F5;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) inset;
    margin-bottom: 20px;
    min-height: 20px;
    padding: 5px;
}