.login-page {
    &__form {
        margin-top: 46px;
        display: flex;
        flex-direction: column;
    }

    &__password-label-container {
        display: flex;
        justify-content: space-between;
    }

    &__forgot-password-text {
        color: #0074D9;
        font-size: 16px;
    }

    &__errors {
        text-align: center;
        color: red;
        margin-top: 10px;
    }

    &__input-wrapper {
        position: relative;

        i {
            position: absolute;
            top: 20%;
            right: 5%;
        }

        input[type="password"],
        input[type="text"] {
            width: 100%;
        }

        input[type="password"]::-ms-reveal {
            display: none;
        }
    }
}
