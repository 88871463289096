.inbox-search-footer {
    display:flex;
    background: #f5f5f5;
    padding: 15px 5px 15px 5px;
    justify-content: space-between;
    font-size: 13px;

    &__input {
        display: flex;
        margin-left: 40px;
        margin-top: 10px;
    }

    &__input-field {
        padding-right: 5px;
    }

    &__input-label {
        align-self: center;
        margin-right: 10px;
    }

    &__input-label:after {
        content: ':';
    }

    &__button {
        font-size: 13px;
        margin-left: 2px;
    }

    &__actions {
        margin: 10px 10px 0 40px;
    }
}