/******* Bootstrap customizations *******/
h1, h2, h3, h4, h5, h6
{
    margin: 0;
}

.btn-toolbar > .tipped-hidden-content + .btn
{
    margin-left: 5px;
}

.tooltip-inner
{
    max-width: 250px;
}

.tooltip .tooltip-arrow
{
    border: 6px solid;
}

.tooltip.in
{
    opacity: 1;
    z-index: 99999999;
}

.tooltip.top
{
    padding: 5px 0;
    margin-top: -11px;
}

.tooltip.right
{
    padding: 0 5px;
    margin-left: 1px;
}

.tooltip.bottom
{
    padding: 5px 0;
    margin-top: 1px;
}

.tooltip.left
{
    padding: 0 5px;
    margin-left: -11px;
}

.tooltip.top .tooltip-arrow
{
    margin-top: -11px;
    border-bottom-width: 0px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    bottom: 1px;
}

.tooltip.left .tooltip-arrow
{
    margin-left: -11px;
    border-right-width: 0px;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    right: 1px;
}

.tooltip.right .tooltip-arrow
{
    border-left-width: 0px;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: 1px;
}

.tooltip.bottom .tooltip-arrow
{
    border-top-width: 0px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    top: 1px;
}

.newPopdown.tooltip.bottom .tooltip-arrow {
    right: 10px;
    left: auto;
}

.newPopdown.tooltip.bottom .tooltip-arrow:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -7px;
    border: 7px solid #eee;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-width: 0;
    z-index: -1;
}

.newPopdown .tooltip-inner {
    padding: 0;
    border: 1px solid #eee;
    box-shadow: #444 2px 2px 1px 1px;
    max-width: none;
    text-align: left;
    font-size: 14px;
}

.newPopdown .edit-component {
    margin-right: 0;
    width: auto;
}

.modal-body {
    max-height: 450px;
    overflow: auto;
}

.connectionRow > tr:first-of-type > td:first-of-type
{
    border-top: 1px solid #dddddd;
}

.connectionRow > tr > td:first-of-type
{
    background-color: white;
    background-image: url('images/link.png');
    background-repeat: no-repeat;
    background-size: 46px;
    background-position: right;
    border: none;
}

.connectionRow > tr:last-of-type > td:first-of-type
{
    background-image: url('images/terminator.png');
    background-repeat: no-repeat;
    background-size: 46px;
    background-position: right;
}

.connectionRow.total > tr:first-of-type > td:first-of-type
{
    border-top: 1px solid #dddddd;
}

.connectionRow > tr.customFields > td:first-of-type,
.connectionRow.detail > tr.customFields > td:first-of-type,
.connectionRow.total > tr.customFields > td:first-of-type,
.connectionRow.exceptiontype > tr.customFields > td:first-of-type,
.connectionRow > tr.customFields:last-of-type > td:first-of-type,
.connectionRow.detail > tr.customFields:last-of-type > td:first-of-type,
.connectionRow.total > tr.customFields:last-of-type > td:first-of-type,
.connectionRow.exceptiontype > tr.customFields:last-of-type > td:first-of-type
{
    background-image: none;
}

.connectionRow.detail > tr:first-of-type > td:first-of-type
{
    border-top: 1px solid #dddddd;
}

th.custom-fields-switch-col 
{
    width: 32px
}

.background-ok
{
    /*color: #468847;*/
    background-color: #dff0d8;
}

.background-warning
{
    /*color: #c09853;*/
    background-color: #fcf8e3;
}

.background-error
{
    color: #b94a48;
    background-color: #f2dede;
}

.background-info
{
    /*color: #3a87ad;*/
    background-color: #d9edf7;
}

.text-ok
{
    color: #468847;
}

.text-warning
{
    color: #c09853;
}

.text-error
{
    color: #b94a48;
}

.text-info
{
    color: #3a87ad;
}

.scrollable
{
    overflow: auto;
}

.max-height
{
    overflow: hidden;
}

.ui-dragon {
    border-width: 10px;
    border: darkslategrey;
    border-bottom: 1px;
}

.ui-dragon td {
    border: 1px solid #333;
    background: #eee;
    opacity: 0.8;
}

.ui-state-active
{
    background: lightyellow;
}

.ui-state-hover
{
    background-color: lightyellow;
}

.drop-highlight td
{
    background-color: #d9edf7;
}

.original-line tr td
{
    vertical-align: middle;
}

/* Data Formatting */
.field-header
{
    margin: 0;
    font-family: inherit;
    font-weight: bold;
    color: inherit;
    font-size: 12px;
}

.field-value
{
    margin: 0 0 9px;
    font-family: "Source Sans Pro";
    font-size: 13px;
    line-height: 18px;
    display: block;
    word-wrap: break-word;
}

.field-value-container-text-overflow
{
    height: 24px;
    display: flex;
    align-items: center;
}

.field-value-text-overflow
{
    font-family: "Source Sans Pro";
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.field-value-inline 
{
    font-family: "Source Sans Pro";
    font-size: 13px;
}

.field-wrapper
{
    margin-bottom: 2px;
}

/*Popdown*/
.popdown
{
    display: none;
}

.popdown-header,
.popdown-body,
.popdown-footer
{
    padding: 8px;
}

.popdown-body
{
    overflow-y: auto;
}

.popdown-body.allow-overflow {
    overflow-y: visible;
}

.popdown-header
{
    border-bottom: 1px solid #eee;
}

    .popdown-header .close
    {
        margin-right: 2px;
    }

.popdown-form
{
    margin-bottom: 0;
}

.popdown-footer
{
    margin-bottom: 0;
    text-align: right;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    -webkit-border-radius: 0 0 6px 6px;
    -moz-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px;
    *zoom: 1;
    -webkit-box-shadow: inset 0 1px 0 #ffffff;
    -moz-box-shadow: inset 0 1px 0 #ffffff;
    box-shadow: inset 0 1px 0 #ffffff;
}

    .popdown-footer:empty
    {
        display: none;
    }

    .popdown-footer:before,
    .popdown-footer:after
    {
        display: table;
        content: "";
    }

    .popdown-footer:after
    {
        clear: both;
    }

    .popdown-footer .btn + .btn
    {
        margin-bottom: 0;
        margin-left: 5px;
    }

    .popdown-footer .btn-group .btn + .btn
    {
        margin-left: -1px;
    }

/*Loader*/

.loading-overlay
{
    width: 100%;
    position: relative;
    /* IE filter */
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
    -moz-opacity: 0.2; /* Mozilla extension */
    -khtml-opacity: 0.2; /* Safari/Conqueror extension */
    opacity: 0.2; /* CSS3 */
}

.loading-image
{
    width: 100%;
    background: url("images/loader.gif");
    background-repeat: no-repeat;
    background-position: center;
}

.loading-overlay img.loading_circle
{
    position: absolute;
    top: 50%;
    left: 50%;
}

/*Fullscreen modal*/

.modal.fullscreen {
    position: fixed;
    top: 3%;
    left: 1%;
    z-index: 1050;
    width: 98%;
    height: 94%;
    max-height: 94%;
    margin: 0px;
    overflow: auto;
    background-color: #ffffff;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, 0.3);
    *border: 1px solid #999;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
}

.modal.fullscreen.fade {
    top: -25%;
    -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
    -ms-transition: opacity 0.3s linear, top 0.3s ease-out;
    -o-transition: opacity 0.3s linear, top 0.3s ease-out;
    transition: opacity 0.3s linear, top 0.3s ease-out;
}

.modal.fullscreen.fade.in {
    top: 3%;
}

.modal.fullscreen.withFooter {
    overflow: hidden;
    height: auto;
    max-height: none;
}

.modal.fullscreen > .modal-body {
    max-height: none;
    height: auto;
}

.modal.fullscreen.withFooter .modal-body {
    max-height: 600px;
}

.preloader
{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

    .preloader > .preloader-inner
    {
        position: relative;
        width: 100%;
        height: 100%;
    }

        .preloader > .preloader-inner .preloader-image,
        .preloader > .preloader-inner .preloader-overlay
        {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
        }

        .preloader > .preloader-inner .preloader-image
        {
            background: url("images/loader.gif") no-repeat center center;
        }

        .preloader > .preloader-inner .preloader-overlay
        {
            opacity: 0.4;
            background: #ffffff;
        }

/* Table Select */
.table tbody tr.selected td,
.table-striped tbody tr.selected td,
.table tbody tr.selected:hover td,
.table-striped tbody tr.selected:hover td
{
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: var(--md-color-brand-primary-base);
    *background-color: var(--md-color-brand-primary-base);
}

/* Table Active */
.unactive .table tbody tr.selected td,
.unactive .table-striped tbody tr.selected td,
.unactive .table tbody tr.selected:hover td,
.unactive .table-striped tbody tr.selected:hover td
{
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #BBB;
    *background-color: #BBB;
}

/* Autocomplete */
.autocomplete.dropdown-menu:before
{
    position: absolute;
    top: -7px;
    left: 7px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}

.autocomplete.dropdown-menu:after
{
    position: absolute;
    top: -6px;
    left: 8px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: '';
}

.label-large
{
    min-width: 18px;
    max-width: 200px;
    display: inline-block;
    line-height: 18px;
    font-size: 14px;
    /*border: 1px white solid*/
    border-color: white;
}

.clickable
{
    cursor: pointer;
}

.drop-disabled
{
    background-color: #f2dede;
}

.drop-enabled
{
    background-color: #dff0d8;
}

#sendEmailModal .controls {
    width: 400px;
}

#changePasswordModal .controls {
    width: 250px;
}

#sendEmailModal .edit-autocompleter-user-container
{
    display: inline-block;
    padding-right: 11px;
    width: 300px;
}

#sendEmailModal .edit-autocompleter-user-container {
    flex: 4;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i
{
    display: block;
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-top: 5px;
    width: 98%;
}


.input-hidden-overflow
{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.details-hidden-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}

.hash-files-top {
    height:100%; 
    display:flex;
    flex-direction:column;
}

.hash-files-display-container {
    display: flex; 
    flex-direction:column;
    flex:1;
}

.hash-files-document-image {
    display:flex;
    flex-direction:column;
    flex:1;
    min-height:550px;
}

.deviation-error-carrier {
    display: flex;
    align-items: baseline;
}

.due-date-deviation {
    padding-left: 5px;
}

.bg-mini .loading-image
{
    background-size: 16px 16px;
}

.image-content .strech-height
{
    height: 100%;
}

.image-content .strech-width
{
    width: 100%;
}

.strech-height
{
    height: 100%;
}

.strech-width
{
    width: 100%;
}

.auto-width
{
    width: auto;
}

.editorFor
{
    white-space: nowrap;
}

    .editorFor.editorFor-datepicker img.ui-datepicker-trigger
    {
        padding: 5px 0 0 4px;
        vertical-align: top;
    }

.chosen-container-multi-width
{
    width: 100%;
}

.input-file-out-of-table
{
    margin: 5px 5px 5px 0;
    padding: 5px 9px;
    height: 100%;
}

.sliding-div
{
    display: block;
    position: absolute;
    z-index: 999;
}

.not-transformed-text
{
    text-transform: none;
}

.dataGrid
{
    position: relative;
}

.fixed-size-table
{
    table-layout: fixed;
}

.small-left-margin
{
    margin-left: 15px !important;
}

.hide-sidebar-btn
{
    padding: 0px 6px;
    height: 16px;
    margin-right: 2px;
}

.document-tab-content
{
    padding-bottom: 50px;
}

.vertical-top
{
    vertical-align: top;
}

.inlineDiv
{
    display: inline;
}

.inlineBlockDiv
{
    display: inline-block;
}

#medius-app,
#medius-app > #medius-app-container,
#medius-app > #medius-app-container > article
{
    display: block;
    height: 100%;
}

#medius-app > #medius-app-container.notification-visible-modifier {
    height: calc(100% - 58px);
}

#medius-app
{
    overflow: hidden;
}

#medius-app > #medius-app-container
{
    left: 0;
    position: relative;
}

#medius-app > #medius-app-container > article
{
    display: block;
    float: left;
    visibility: hidden;
}

#medius-app > #medius-app-container > article.visible
{
    visibility: visible;
}

#Editor .CodeMirror-scroll
{
    height: 100%;
    min-height: 200px;
}


.resizable-image
{
    max-height: none;
    max-width: none;
}

.resizable-context
{
    max-height: none;
    max-width: none;
}

.readonly-box-position
{
    position: relative;
}

.readonly-box
{
    color: #2F3540;
    background-color: #E5E5E5;
    border-color: #DAE1E4;
    position: relative;
}

.readonly-cover
{
    background-color: #E5E5E5;
    z-index: 1001;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.pagination-small a
{
    line-height: 20px;
}

.pagination-small ul > li > a,
.pagination-small ul > li > span
{
    font-size: 11.9px;
    padding: 2px 10px;
}

.pagination-small ul > li:first-child > a,
.pagination-small ul > li:first-child > span
{
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

#workflow-picker
{
    margin-bottom: 10px;
}

.navbar {
    margin-bottom: 10px;
}

.navbar .brand {
    padding-top: 8px;
    padding-bottom: 9px;
}

.navbar .nav .dropdown-toggle .caret {
    margin-top: 6px;
}

.nav-pills > li {
    padding-top: 1px;
    line-height: 19px;
}

.task-tabs
{
    margin-bottom: 0px;
}

.bordered [class*="row-fluid"],
.bordered [class*="row"]
{
    border: 1px solid #dddddd;
    margin-top: -1px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bordered [class*="row-fluid"]:first-child,
.bordered [class*="row"]:first-child
{
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    margin-top: 0px;
}

.bordered [class*="row-fluid"]:last-child,
.bordered [class*="row"]:last-child
{
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
}

.right-align
{
    text-align: right;
}

.center-align {
    text-align: center;
}

#task-tabs
{
    margin-bottom: 6px;
}

.t_ContentContainer
{
    padding: 0;
}

.modal
{
    max-height: 550px;
    min-width: 730px;
    margin-top: 53px;
}

.modal-task-group
{
    min-width: 730px;
}

.modal-container
{
    position: absolute;
    left: 50%;
}

.modal-box
{
    position: relative;
    left: -50%;
    margin-left: 0; /*overrides def boostrap margin-left: 280px*/
}

#sendEmailModal.modal {
    margin-left: -350px;
}

#sendEmailModal .edit-autocompleter-suggestions {
    max-height: 90px;
}

#sendEmailModal .edit-email-container {
    display: flex;
    padding-right: 11px;
    width: 300px;
    flex: 4;
}

#sendEmailModal .edit-email-container input {
    flex: 0.99;
}

#sendEmailModal .user-controls, #sendEmailModal .email-controls {
    display: flex;
}

#sendEmailModal .user-controls button, #sendEmailModal .email-controls button {
    flex: 1;
}

.modal-control
{
    padding-top: 5px;
}

#outbox {
    cursor: pointer;
    width: 120px;
    margin-top: 10px;
}

.outbox-content {
    width: 375px;
    max-height: 50vh;
    overflow: auto;
    margin: -8px;
    font-size: 13px;
}

.outbox-tasks-header {
    background: #f7f7f7;
    padding: 5px 10px;
    display: block;
    font-weight: bold;
}

.outbox-empty-info {
    display: block;
    padding: 5px 10px;
}

.outbox-tasks {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.outbox-tasks > .outbox-item {
    padding: 5px 10px;
    border-top: 1px solid #f7f7f7;
}

.outbox-tasks > .outbox-item:first-child {
    border-top: 0;
}

.outbox-item a {
    color: #607efe;
}

.document-errors-list {
    max-height: 100px;
    overflow: auto;
}

#open-pending-async-exports
{
    padding: 10px 15px 10px;
    cursor: pointer;
}

#pending-async-exports-modal .async-exports div.query-item:hover,
#ongoing-bulk-updates-modal div.query-item:hover{
    background-color: #f5f5f5;
}

.pending-async-exports-modal {
    min-width: 1000px;
    margin-left: -500px; /* needed in this version of bootstrap to keep modal in center*/
}

#pending-async-exports-modal .async-exports div.query-item,
#ongoing-bulk-updates-modal div.query-item {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    height: 23px;
    margin-bottom: 7px;
    padding-top: 3px;
    padding-left: 9px;
}

#pending-async-exports-modal .async-exports div.query-item:hover .item-operator,
#ongoing-bulk-updates-modal div.query-item:hover .item-operator {
    visibility: inherit;
}

#pending-async-exports-modal .async-exports div.query-item .item-operator,
#ongoing-bulk-updates-modal div.query-item .item-operator {
    visibility: hidden;
    cursor: pointer;
}

#business-rule-editor .collapse.in
{
    overflow: visible !important;
}

#business-rule-editor textarea {
    height: auto
}

#business-rule-editor .CodeMirror {
    height: auto;
}

.attachments table {
    margin: 0;
    padding: 0;
    width: 100%;
}

.attachments td.column-icon {
    min-width: 60px;
    border: none;
    margin: 0;
    padding: 0;
}

.attachments td.content-column {
    border: none;
    padding: 0 10px;
    width: 90%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.attachments .carried-over {
    color: #999;
}

input[type="file"].file-input {
    margin: 5px 5px 0 0;
    padding: 5px 9px;
    height: 100%;
    width: 50%;
}

  
.javascript-runtime-tests .areas {
    list-style-type: none; 
    margin-left: 0px;
}

.javascript-runtime-tests .areas > li {
    margin-top: 15px;
}

.javascript-runtime-tests .areas > li > a {
    color: #3a87ad;
}

.javascript-runtime-tests .areas .cases .passed a {
    color: #468847;
}

.javascript-runtime-tests .areas .cases .failed a {
    color: #b94a48;
}

.javascript-runtime-tests .areas .cases .ongoing a {
    color: #3a87ad;
}

#confirmModal {
    min-width: 390px;
    width: 390px;
    margin-left: -160px;
}

.graphWindow {
    display: block;
    position: fixed;
    top: 0px;
}

select.smaller {
    font-size: 12px;
    height: 27px;
    line-height: 27px;
}

.scrollable-ko {
    overflow: hidden;
    position: relative;
}

.scrollable-ko > *:first-child {
    position: relative;
    display: block;
    top: 0;
    left: 0;
}

.scrollable-ko .scroll {
    position: absolute;
    display: none;
    bottom: 0;
    right: 0;
    background: #f0f0f0;
}

.scrollable-ko .scroll.scroll-y {
    top: 0;
    width: 4px;
}

.scrollable-ko .scroll.scroll-y.bigger {
    width: 12px;
} 

.scrollable-ko .scroll.scroll-x {
    left: 0;
    height: 4px;
}

.scrollable-ko .scroll.scroll-x.bigger {
    height: 12px;
}

.scrollable-ko .scroll-x + .scrollable-ko .scroll-y {
    bottom: 4px;
}

.scrollable-ko .scroll.on {
    display: block;
}

.scrollable-ko .scroll.on.empty {
    display: none;
}

.scrollable-ko .scroll .handler {
    display: block;
    background: #dddddd;
    position: relative;
    cursor: pointer;
    margin: 0;
}

.scrollable-ko .scroll.bigger .handler {
    background: #bbbbbb;
}

.scrollable-ko .scroll.scroll-y .handler {
    width: 4px;
}

.scrollable-ko .scroll.scroll-y.bigger .handler {
    width: 8px;
    margin: 0 2px;
}

.scrollable-ko .scroll.scroll-x .handler {
    height: 4px;
}

.scrollable-ko .scroll.scroll-x.bigger .handler {
    height: 8px;
    margin: 2px 0;
}

#button-section-add {
    text-align: center;
}

.comments > header {
    margin: 15px 0;
}

.attachments form {
    margin: 0;
}

.history .filter {
    display: inline;
    font-weight: bold;
}

.history .main-detail-actions {
    width: initial;
}

.history .filter input {
    margin-bottom: 5px;
}

#main-menu-bar {
    width: auto;
}

.user-settings-modal .modal-body {
    overflow: visible;
}

.user-settings-modal .tab-content {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 400px;
}

.user-settings-modal .tab-content > div {
    min-height: 200px;
}

.user-settings-modal .tab-general .control-group {
    width: 95%;
}

.row-with-editor {
    margin-bottom: 10px;
}

.medius-label {
    background-color: #ed1c24;
}

.historyText {
  white-space: pre-wrap;
}

.required-indicator {
    color: #ff0000;
    top: 0;
    width: 5px;
    height: 24px;
    background: transparent;
    font-size: 13px;
}

@media (min-width: 1200px) {

    .row-fluid.compact [class*="span"] {
        min-height: 20px;
    }

}

.word-wrapped {
    word-wrap: break-word;
}

/*lightgrey background on image container*/
#image-container {
    background-color: #acacac;
    overflow: hidden;
    height: 100%;
}

.tooltip.tooltip-results-visible {
    opacity: 0.3;
}

.tooltip.tooltip-results-visible:hover {
    opacity: 1;
}

.document-image-tab-container {
    height: 100%;
}

.send-email-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 5px;
}