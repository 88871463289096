.passwordManagement {
    padding: 0 20px;

    &__content {
        margin-left: 40px;
    }

    &__dialog-content {
        margin: "25px";
        text-align: "center";
    }

}