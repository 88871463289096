.document-task-side {
    color: black;
    display: flex;
    flex-direction: column;
    flex-grow:1;
}

.document-name-risk-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.document-name-risk-container > h4{
    width: 80%;
}

.document-name-risk-container #copy-to-cart-btn {
    width: 125px;
    text-align: right;
}

.document-task-tabs {
    display: flex;
    flex-direction: column;
    min-height: 0%;
    flex-grow: 1;
    height: 100%;
}

.document-task-container {
    display: flex;
    flex-direction: column;
    min-height: 0%;
    flex-grow:1;
    height: 100%;
}

.document-task-container-inner {
    display: flex;
    flex-direction: column;
    min-height: 0%;
    flex-grow: 1;
    height: 100%;
}

.document-task-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    min-height: 0%;
    flex-grow: 1;
    height: 100%;
}

.document-task-tab {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0%;
    height: 100%;
}

.document-task-tab-inner {
    height: 100%;
}

.documentLinks__mainContainer { 
    width: auto;
}