#settings-editor table tr.row_selected td {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #0074cc;
    *background-color: #0055cc;
    background-image: -ms-linear-gradient(top, #0088cc, #0055cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0055cc));
    background-image: -webkit-linear-gradient(top, #0088cc, #0055cc);
    background-image: -o-linear-gradient(top, #0088cc, #0055cc);
    background-image: -moz-linear-gradient(top, #0088cc, #0055cc);
    background-image: linear-gradient(top, #0088cc, #0055cc);
    background-repeat: repeat-x;
    border-color: #0055cc #0055cc #003580;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#0088cc', endColorstr='#0055cc', GradientType=0);
    filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}

#settings-editor,
#settings-start-page {
    position: relative;
    min-height: 500px;
}

#settings-editor .action-buttons {
    min-height: 48px;
}

#settings-editor ul.nav-tabs {
    margin-bottom: 11px;
}

#settings-editor-browser {
    position: relative;
}

#settings-editor-browser #choose-company-context {
  
}

#settings-start-page .span4 {
    height: 450px;
    margin-left: 0;
    margin-right: 1.7%;
}

#settings-start-page .medius-grid-container {
    margin-top: 15px;
}

#settings-start-page table a {
    display: block;    
}

#additional-buttons {
    margin: 10px 0;
}

#additional-buttons .btn {
    margin-right: 10px;
}

#settings-item .entity-configuration #entity-config-options {
    margin-bottom: 15px;
}

#settings-item .entity-configuration #entity-config-content {
    margin-top: 15px;
}