.inbox-left-non-fullscreen {
    height: 100%;
}

.simple-search {
    margin-bottom: 0;
}

.simple-search__input {
    max-height: 16px;
}

h2
{
    margin-top: 0px;
    margin-bottom: 7px
}

#inbox-right
#fullscreen-inbox-right {
    position: relative;
}

#inbox-right,
#fullscreen-inbox-right {
    input[type=text]:focus, textarea:focus {
        border-color: var(--md-color-brand-primary-base);
        box-shadow: none;
    }

    input[type=text]::placeholder, textarea::placeholder {
        color: var(--md-color-text-placeholder);
        font-style: italic;
        font-size: 12px;
    }
}

#task
{
    position: fixed;
    bottom: 0;
    right: 20px;
}

#task span.separator {
    display: inline-block;
    width: 0;
    height: 24px;
    margin: 0 8px -9px;
    border-right: 1px solid #000000;
}

.alert.document-view__alert {
    margin-bottom: 10px;
}

#inbox-scroll
{
    width: 100%;
    overflow: auto;
    position: relative;
}

.sub-header
{
    padding-bottom: 5px;
    margin: 10px 0 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
}

.sub-header h2
{
    line-height: 1;
}

.sub-header h3
{
    line-height: 1;
}

*:focus {
    outline:none;
}

.unread {
    font-weight: bold;
}

table tr.even.row_selected td,
table tr.odd.row_selected td
{
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #0074cc;
    *background-color: #0055cc;
    background-image: -ms-linear-gradient(top, #0088cc, #0055cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0055cc));
    background-image: -webkit-linear-gradient(top, #0088cc, #0055cc);
    background-image: -o-linear-gradient(top, #0088cc, #0055cc);
    background-image: -moz-linear-gradient(top, #0088cc, #0055cc);
    background-image: linear-gradient(top, #0088cc, #0055cc);
    background-repeat: repeat-x;
    border-color: #0055cc #0055cc #003580;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#0088cc', endColorstr='#0055cc', GradientType=0);
    filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}

table tr.odd.row-add td
{
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #5bb75b;
    *background-color: #51a351;
    background-image: -ms-linear-gradient(top, #62c462, #51a351);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#51a351));
    background-image: -webkit-linear-gradient(top, #62c462, #51a351);
    background-image: -o-linear-gradient(top, #62c462, #51a351);
    background-image: -moz-linear-gradient(top, #62c462, #51a351);
    background-image: linear-gradient(top, #62c462, #51a351);
    background-repeat: repeat-x;
    border-color: #51a351 #51a351 #387038;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#62c462', endColorstr='#51a351', GradientType=0);
    filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}

table tr.even.row-add td
{
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #5bb75b;
    *background-color: #51a351;
    background-image: -ms-linear-gradient(top, #62c462, #51a351);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#51a351));
    background-image: -webkit-linear-gradient(top, #62c462, #51a351);
    background-image: -o-linear-gradient(top, #62c462, #51a351);
    background-image: -moz-linear-gradient(top, #62c462, #51a351);
    background-image: linear-gradient(top, #62c462, #51a351);
    background-repeat: repeat-x;
    border-color: #51a351 #51a351 #387038;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#62c462', endColorstr='#51a351', GradientType=0);
    filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}

.table tbody tr.odd.row-add:hover td, .table tbody tr:hover th
{
    color: rgba(255, 255, 255, 0.75);
}

.table tbody tr.even.row-add:hover td, .table tbody tr:hover th
{
    color: rgba(255, 255, 255, 0.75);
}

.table thead th.sorting-asc {
    text-decoration: overline;
    color: #cccccc;
}

.table thead th.sorting-desc {
    text-decoration: underline;
    color: #cccccc;
}

.table thead th.column {
    cursor: pointer;
}

#inbox-left
#fullscreen-inbox-left {
    position: relative;
}

#inbox-left #inbox-sidebar
{
    padding: 8px 0 0;
    margin: 0;
    position: absolute;
    z-index: 999;
}

#inbox-left #inbox-sidebar #hide-button-container 
{
    position: relative;
}

#inbox-left #inbox-sidebar .show-hide-sidebar-btn 
{
    position: absolute;
    top: 1px;
    right: 8px;
}

#inbox-left span.column-name {
    text-transform: none;
}

#inbox-left .indicator.remove 
{
    background: none;
    cursor: pointer;
    display: inline;
    margin-left: -25px;
    margin-right: 5px;
    opacity: 0.3;
    position: absolute;
    top: 5px;
}

#inbox-left #inbox-sidebar li.saved-search-item
{
    margin: 0 -15px;
    width: 110%;
    padding: 0 0 0 15px;
    line-height: 21px;
}

#inbox-left #inbox-sidebar li.saved-search-item td.search-item-icon-cell
{
    width: 18px;
}

#inbox-left #inbox-sidebar li.saved-search-item td.search-item-name-cell
{
    width: 118px;
}

#inbox-left #inbox-sidebar li.saved-search-item td.search-item-name-cell a
{
    display: block;
}

#inbox-left #inbox-sidebar li.saved-search-item:hover a
{
    text-decoration: none;
}

#inbox-left #inbox-sidebar li.saved-search-item:hover
{
     background-color: #eeeeee;
}

#inbox-left #inbox-sidebar li.saved-search-item .delete-search {
    display: none;
}

#inbox-left #inbox-sidebar li.saved-search-item:hover .delete-search {
    display: block;
}

#inbox-left #inbox-sidebar li.saved-search-item.active
{
    background-color: #0088CC;
}

#inbox-left #inbox-sidebar li.saved-search-item.active a
{
    color: #ffffff;
}

#inbox-left #search-filter-alert
{
     margin: 10px 0 0;
}

#inbox-left #search-filter-alert .filter-name
{
    font-weight: bold;
    font-style: italic
}

.filter-name-reset {
    cursor: pointer;
}

#separate-tab
{
    display: none;
}

.margin-for-fullscreen-btn {
     margin-bottom: 10px;
     padding-right: 120px;
}

#local-draft {
    vertical-align: middle;
}

.side-menu-inner-width-90 {
    width: 90px;
    overflow: hidden;
    white-space: nowrap;
}

.label-column-width-60 {
    width: 60px;
    cursor: pointer;
}


.color-box 
{
   display: inline-block;
   border-radius: 3px;
   width: 16px;
   height: 16px;
   position: absolute;
}

.label-description {
    display: inline;
    margin-left: 20px;
    text-shadow: none;
}

.label-link
{
    color: inherit;
}

.negative-margin {
    margin-left: -4px;
    margin-right: -4px;
}

.label-dropdown {
    max-height: 210px;
    overflow-y: auto;
}