.item-section-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.item-section-header {
    margin-bottom: 15px;
}

.item-section-body {
    display:flex;
    min-height:0;
    height: 100%;
}

.general-tab {
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
}

.general-tab-label {
    margin-bottom: 5px;
}

.general-tab-column {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.general-tab-column-item {
    width: 250px;
    height: 50px;
    margin-bottom: 10px;
}

.administration-general-input {
    max-width: 192px;
}

.administration-page-header {
    margin-left: 50px;
    color: #333;
}
