#keyboardShortcutsModal .key {
    background: none repeat scroll 0 0 #EFF0F2;
    border-radius: 1px;
    border-top: 1px solid #F5F5F5;
    box-shadow: 0 0 25px #EEEEEE inset, 0 1px 0 #C3C3C3, 0 2px 0 #C9C9C9, 0 2px 3px #333333;
    color: #777777;
    display: inline-block;
    font: 11px sans-serif;
    font-weight: bold;
    height: 14px;
    min-width: 14px;
    padding: 2px;
    text-align: center;
    text-shadow: 0 1px 0 #F5F5F5;
}

#keyboardShortcutsModal ul {
    list-style: none;
    margin: 10px 0;
}

#keyboardShortcutsModal li {
    margin: 4px 0;
}

#keyboardShortcutsModal .shortcut-label {
    width: 200px;
    vertical-align: middle;
    text-align: right;
    display: inline-block;
    margin-right: 4px;
}

#keyboardShortcutsModal .shortcut-group {
    width: 330px;
    float: left;
}

#keyboardShortcutsModal .shortcut-group:nth-child(2n+1) {
    margin-right: 20px;
    clear: left;
}

#keyboardShortcutsModal .modal {
    max-height: 600px;
    min-width: 730px;
    margin-top: 53px;
}

#keyboardShortcutsModal .modal-body
{
    max-height: 500px;
    overflow: auto;
}