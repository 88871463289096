.modal-footer .btn {
    float: right;
}

.modal-footer .btn.btn-close {
    float: left;
}

.limited-size-popdown {
    max-width: 450px;
}

.limited-size-popdown .popdown-body {
    max-height: 200px;
}

.modal.modal-align-center {
    transform: translate(-50%, -10%);
    margin-left: 0;
    width: auto;
    max-width: 90%;
}