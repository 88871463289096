$locked-column-z-index: 50;

@function underLockedColumn() {
    @return $locked-column-z-index - 1;
}

.gridContainer {
    height: 100%;

    &__grid {
        height: 99%;

        &__centerCell {
            text-align: center !important;
        }

        &__exceptionsGrid {
            height: 100%;
            max-height: 300px;
            .k-toolbar.k-grid-toolbar { min-height: 55px; }
        }

        &__lockedColumnCell {
            z-index: $locked-column-z-index;
        }

        .k-toolbar.k-grid-toolbar { min-height: 55px; }
    }

    .limitContainer > .k-animation-container {
        z-index: underLockedColumn();
    }
}

.openPopupButton{
    overflow: visible;
    border-radius: 5px;
}

.popupAnchor {
    position: fixed;
    padding-top: 20px;
}

.popupContent {
    background-color: #ffffff;
}

.exceptionsBadge {
    z-index: underLockedColumn();
}

th.k-header.active > div > div {
    color: #fff;
    background-color: #ff6358;
}

.headerCellInfo {
    font-size: 14px;
    margin-left: 5px;
}

.checkboxHeaderCell{
    padding-left: 5px;     
}