.pdf-viewer-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%; /*height set because in fullscreen we use snapToWindow binding which makes fixed height in pixels and we cannot adapt via flex*/
}

.pdf-viewer-iframe-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.pdf-viewer-iframe {
    width: calc(100% - 4px);
    height: calc(100% - 20px);
    flex: 1;
    border: 1px solid #e8e8e8;
}

.resizing-in-progress .pdf-viewer-iframe {
    position: relative;
    pointer-events: none ;
}
