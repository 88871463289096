.img-mini
{
	display: inline-block;
	width: 16px;
	height: 16px;
	line-height: 16px;
	background-repeat: no-repeat;
}

.img-small
{
	display: inline-block;
	width: 24px;
	height: 24px;
	line-height: 24px;
	background-repeat: no-repeat;
}

.img-medium
{
	display: inline-block;
	width: 32px;
	height: 32px;
	line-height: 32px;
	background-repeat: no-repeat;
	background-position: 0, 0;
}

.img-large
{
	display: inline-block;
	width: 48px;
	height: 48px;
	line-height: 48px;
	background-repeat: no-repeat;
}

.img-invoice-gear.img-mini
{
	background-image: url("libs/icons/icons/s16/invoice-gear.png");
}
.img-invoice-gear.img-small
{
	background-image: url("libs/icons/icons/s24/invoice-gear.png");
}
.img-invoice-gear.img-medium
{
	background-image: url("libs/icons/icons/s32/invoice-gear.png");
}
.img-invoice-gear.img-large
{
	background-image: url("libs/icons/icons/s48/invoice-gear.png");
}

.img-invoice-employee.img-mini
{
	background-image: url("libs/icons/icons/s16/invoice-employee.png");
}

.img-invoice-employee.img-small
{
	background-image: url("libs/icons/icons/s24/invoice-employee.png");
}

.img-invoice-employee.img-medium
{
	background-image: url("libs/icons/icons/s32/invoice-employee.png");
}

.img-invoice-employee.img-large
{
	background-image: url("libs/icons/icons/s48/invoice-employee.png");
}

.img-employee.img-mini
{
	background-image: url("libs/icons/icons/s16/employee.png");
}

.img-employee.img-small
{
	background-image: url("libs/icons/icons/s24/employee.png");
}

.img-employee.img-medium
{
	background-image: url("libs/icons/icons/s32/employee.png");
}

.img-employee.img-large
{
	background-image: url("libs/icons/icons/s48/employee.png");
}

.img-gear.img-mini
{
	background-image: url("libs/icons/icons/s16/gear.png");
}

.img-gear.img-small
{
	background-image: url("libs/icons/icons/s24/gear.png");
}

.img-gear.img-medium
{
	background-image: url("libs/icons/icons/s32/gear.png");
}

.img-gear.img-large
{
	background-image: url("libs/icons/icons/s48/gear.png");
}

.img-sum.img-mini
{
	background-image: url("libs/icons/icons/s16/sum.png");
}

.img-sum.img-small
{
	background-image: url("libs/icons/icons/s24/sum.png");
}

.img-sum.img-medium
{
	background-image: url("libs/icons/icons/s32/sum.png");
}

.img-sum.img-large
{
	background-image: url("libs/icons/icons/s48/sum.png");
}

.img-invoice-search.img-mini
{
	background-image: url("libs/icons/icons/s16/invoice-find.png");
}

.img-invoice-search.img-small
{
	background-image: url("libs/icons/icons/s24/invoice-find.png");
}

.img-invoice-search.img-medium
{
	background-image: url("libs/icons/icons/s32/invoice-find.png");
}

.img-invoice-search.img-large
{
	background-image: url("libs/icons/icons/s48/invoice-find.png");
}

.img-invoice.img-mini
{
	background-image: url("libs/icons/icons/s16/invoice.png");
}

.img-invoice.img-small
{
	background-image: url("libs/icons/icons/s24/invoice.png");
}

.img-invoice.img-medium
{
	background-image: url("libs/icons/icons/s32/invoice.png");
}

.img-invoice.img-large
{
	background-image: url("libs/icons/icons/s48/invoice.png");
}

.img-link.img-mini
{
	background-image: url("libs/icons/icons/s16/link.png");
}

.img-link.img-small
{
	background-image: url("libs/icons/icons/s24/link.png");
}

.img-link.img-medium
{
	background-image: url("libs/icons/icons/s32/link.png");
}

.img-link.img-large
{
	background-image: url("libs/icons/icons/s48/link.png");
}

.img-link-ok.img-mini
{
	background-image: url("libs/icons/icons/s16/link-ok.png");
}

.img-link-ok.img-small
{
	background-image: url("libs/icons/icons/s24/link-ok.png");
}

.img-link-ok.img-medium
{
	background-image: url("libs/icons/icons/s32/link-ok.png");
}

.img-link-ok.img-large
{
	background-image: url("libs/icons/icons/s48/link-ok.png");
}

.img-link-warn.img-mini
{
	background-image: url("libs/icons/icons/s16/link-warn.png");
}

.img-link-warn.img-small
{
	background-image: url("libs/icons/icons/s24/link-warn.png");
}

.img-link-warn.img-medium
{
	background-image: url("libs/icons/icons/s32/link-warn.png");
}

.img-link-warn.img-large
{
	background-image: url("libs/icons/icons/s48/link-warn.png");
}

.img-link-info.img-mini
{
	background-image: url("libs/icons/icons/s16/link-info.png");
}

.img-link-info.img-small
{
	background-image: url("libs/icons/icons/s24/link-info.png");
}

.img-link-info.img-medium
{
	background-image: url("libs/icons/icons/s32/link-info.png");
}

.img-link-info.img-large
{
	background-image: url("libs/icons/icons/s48/link-info.png");
}

.img-link-error.img-mini
{
	background-image: url("libs/icons/icons/s16/link-error.png");
}

.img-link-error.img-small
{
	background-image: url("libs/icons/icons/s24/link-error.png");
}

.img-link-error.img-medium
{
	background-image: url("libs/icons/icons/s32/link-error.png");
}

.img-link-error.img-large
{
	background-image: url("libs/icons/icons/s48/link-error.png");
}

.img-automatically-created
{
	background-image: url("libs/icons/icons/automatic-creation-indicator.svg");
    background-size: 100% 100%;
}