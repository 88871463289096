$splitter-space: 0 15px 0 20px;

@mixin fill-height {
    height: 100%;
}

@mixin fill-margin-bottom {
    margin-bottom: 20px;
}

.authorizationGroups {
    @include fill-height();

    .k-splitter {
        @include fill-height();
        border-style: none;
    }

    .administration-page-header {
        font-size: 24px;
    }

    &__right-side,
    &__left-side {
        @include fill-height();

        &__title-bar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__left-side {
        display: flex;
        flex-flow: column;
        align-items: stretch;
        margin: $splitter-space;
    }

    &__right-side {
        margin: $splitter-space;
    }

    &__configuration-property {
        position: relative;

        display: block;
        @include fill-margin-bottom();

        span.k-textbox-container {
            width: 100%;
        }

        &__currency {
            width: 220px;
        }

        &__text > span {
            width: 100%;
        }

        &__text {
            @include fill-margin-bottom();
        }
    }

    &__contentPanel {
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        &__panels {
            height: 100%;
            margin-top: 10px;
            overflow: hidden;
        }

        &__panel {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden !important;

            > *:first-child,
            > * > *:first-child,
            > * > * > *:first-child {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
            }
        }
    }

    .general-tab-wrapper {
        width: 50%; 
    }

    .section-header {
        display: flex;
        color: rgba(0, 0, 0, 0.87);
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 10px;
    }

    .section-margin {
        margin-bottom: 5px;
        margin-bottom: 10px;
    }

    .required .k-label:before {
        content: "* ";
        color: red;
    }

    .limitTab {
        &__debitLimit,
        &__creditLimit {
            .checkBoxContainter {
                margin-top: 10px;
            }
        }
    }
}
