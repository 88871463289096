.generalLabelSelector{
    display: flex;
    margin-top: 8px;
    min-height: 29px;
    &__wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__customCellContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 5px 0px 5px;

        span{
            margin: 0px;
            padding: 0px;
        }
    }

    &__labelColorSquare{
        border-radius: 3px;
        width: 17px;
        height: 16px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__currentLabelListContainer{
        width: 100%;
        margin-left: 4px;
    }

    &__labelInList{
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        border-radius: 2px;
        padding: 0 8px;
        margin: 2px 4px;
    }

    &__tickIconWhite{
        filter: invert(1);
    }
}