.notification-center {
    position: relative;
}

.notification-center a {
    color: #17b3de;
    cursor: pointer;
}

.notification-center .pop-up {
    padding: 0;
    cursor: default;
    min-width: 300px;
}

.notification-center .pop-up-header {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    padding: 6px;
    border-bottom: 1px solid #ebebeb;
}

.notification-center .pop-up-list {
    display: flex;
    border: none;
    width: 100%;
    flex-direction: column;
    border-radius: 0 0 2px 2px;
}

    .notification-center .pop-up-no-notifications {
        min-height: 200px;
        text-align: center;
        color: #a0a0a0;
        font-size: 17px;
        position: relative;
        transform: translateY(45%);
    }

.notification-center .notification {
    display: flex;
}

    .notification-center .notification:last-child {
        border-radius: 0 0 2px 2px;
    }

    .notification-center .notification:last-child .indicator {
        border-radius: 0 0 0 2px;
    }

.notification-center .notification .indicator {
    background-color: #17b3de;
    width: 4px;
}

.notification-center .notification.read .indicator {
    background-color: transparent;
}

.notification-center .notification a {
    display: flex;
    padding: 6px;
    cursor: pointer;
    width: 100%;
    color: black;
    text-decoration: none;
}

.notification-center .notification:hover {
    background-color: #f6f6f6;
}

.notification-center .notification .icon {
    min-width: 40px;
    height: 40px;
    background-color: #17b3de;
    border-radius: 50%;
    margin-right: 5px;
}

.notification-center .notification .icon .rocket {
    width: 20px;
    height: 20px;
    fill: white;
    padding-top: 10px;
    padding-left: 10px;
}

.notification-center .notification .content {
    display: flex;
    flex-direction: column;
    padding-left: 3px;
    justify-content: space-between;
}

.notification-center .notification .content .time {
    color: #a0a0a0;
}

.notification-center .unreadbadge {
    position: absolute;
    left: 16px;
    top: -2px;
    z-index: 1;
    height: 17px;
    border-radius: 50%;
    background-color: #17b3de;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 11px;
    width: 20px;
}