.medius-grid-action-buttons {
    margin: 5px 0 15px;
}

.medius-grid footer .showing {
    line-height: 22px;
    font-size: 12px;
    margin: 8px;
    margin-right: 4px;
    float:right;
}

.medius-grid footer .show-more {
    float: right;
    margin: 8px;
    margin-left: 4px;
    button{
        margin-left: 2px;
    }
}

.medius-grid {
    display: block;
    position: relative;
    font-size: 14px;
    margin-bottom: 18px;
    height: auto;
    outline: 0 none;
    overflow: visible;
}

.medius-grid .grid-container {
    display: block;
    border-bottom: 1px solid var(--md-color-divider-light);
}

.medius-grid .grid {
    display: table;
    margin-bottom: 0;
    height: auto;
    outline: 0 none;
    empty-cells: show;
}

/* tweak for Chrome */
.medius-grid .grid.layout-fixed {
    table-layout: fixed;
}

.medius-grid > section {
    border-bottom: 1px solid var(--md-color-divider-light);
    background: #ffffff;
}

.medius-grid > section > article > p {
    margin: 5px;
}

.medius-grid > section > footer {
    background-color: var(--md-color-page-background-empty);
}

.medius-grid > section > footer.table-empty {
    border-top-width: 0;
}

.medius-grid > section > footer:empty {
    border-top: 0;
}

.medius-grid .grid > aside {
    display: table-column-group;
}

.medius-grid .grid > aside > div {
    display: table-column;
}

.medius-grid .grid > header {
    display: table-header-group;
}

.medius-grid .grid > article {
    display: table-row-group;
}

.medius-grid .grid-row {
    display: table-row;
}

.medius-grid .grid > article .grid-row.drop-disabled > .grid-cell {
    background-color: #f2dede;
}

.medius-grid .grid > article .grid-row.drop-enabled > .grid-cell {
    background-color: #dff0d8;
}

.medius-grid .grid-row > .grid-cell {
    display: table-cell;
    border-bottom: 1px solid var(--md-color-divider-light);
    vertical-align: middle;
    overflow: hidden;
}

.medius-grid .grid-row > .grid-cell.center > section {
    text-align: center;
}

.medius-grid .grid > header .grid-row > .grid-cell > header,
.medius-grid .grid-row > .grid-cell > section {
    display: block;
    padding: 2px 4px;
    line-height: 18px;
    min-height: 18px;
    text-align: left;
    vertical-align: top;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
}

.medius-grid .grid-row > .grid-cell > section.right-align {
    text-align: right;
}

.medius-grid .grid-row > .grid-cell > section.center-align {
    text-align: center;
}

.medius-grid .grid-row > .grid-cell > section > span, 
.medius-grid .grid-row > .grid-cell > section > div {
    vertical-align: middle;
}

.medius-grid .grid > article .grid-row > .grid-cell > header {
    display: none;
    height: 0;
    padding: 0;
    margin: 0;
    line-height: 0;
    border: 0;
}

.medius-grid .grid-row > .grid-cell.multiselect > span {
    text-align: center;
    vertical-align: middle;
}

.medius-grid .grid-row > .grid-cell.multiselect input[type=checkbox] {
    margin: 0;
}

.medius-grid .grid-row > .grid-cell select {
    width: 98%;
    margin: 0 auto;
}

.medius-grid .grid > article .grid-row.selected > .grid-cell,
.medius-grid .grid > article .grid-row.selected:nth-child(2n+1) > .grid-cell {
    background-color: var(--md-color-table-row-background-selected);
}

.medius-grid .grid-row > .grid-cell:last-child {
    border-right: 0;
}

.medius-grid .grid > article .grid-row:last-child > .grid-cell {
    border-bottom: 0;
}

.medius-grid .viewport-bigger .grid-row > .grid-cell:last-child {
    border-right: 1px solid #dddddd;
}

.medius-grid .grid-row > .grid-cell > header > span:empty,
.medius-grid span.display-component *:empty {
    display: block;
}

.medius-grid header .grid-row > .grid-cell,
.medius-grid .grid-cell header {
    font-weight: bold;
    vertical-align: bottom;
    padding: 0;
    font-size: 12px;
}

.medius-grid header .grid-row > .grid-cell {
    vertical-align: middle;
    border-bottom: 1px solid var(--md-color-divider-dark);
}

.medius-grid .grid-row.selected > .grid-cell header {
    color: #ffffff;
}

.medius-grid header .sortable {
    cursor: pointer;
}

.medius-grid header .sortable.asc {
    text-decoration: overline;
}

.medius-grid header .sortable.desc {
    text-decoration: underline;
}

.medius-grid .grid > header .grid-cell span.column-name {
    display: block;
    width: 100%;
    padding: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.medius-grid .grid > header .grid-cell .ui-resizable-e {
    cursor: col-resize;
    width: 10px;
}

.medius-grid article > .clickable {
    cursor: pointer;
}

.medius-grid .grid > header .edit-component {
    display: block;
    margin: 4px auto;
    width: calc(100% - 8px);
}

.medius-grid .grid > header .edit-component > input[type=text] {
    color: #aaaaaa;
    min-height: 24px;
}

.medius-grid .edit-component > input[type=text] {
    padding: 3px 4px;
    font-size: 12px;
    width: 100%;
}

.medius-grid > footer > * {
    margin-top: 10px;
    margin-bottom: 10px;
}

.medius-grid > footer > button {
    font-size: 12px;
    height: 30px;
}

.medius-grid > footer > select {
    width: auto;
    margin-left: 10px;
    font-size: 12px;
    margin-top: 8px;
}

.medius-grid > footer .pagination {
    margin-left: 10px;
}

.medius-grid .multiselect {
    vertical-align: middle;
    text-align: center;
}

.medius-grid .multiselect > input {
    margin: 0;
}

.medius-grid .grid > header .multiselect > input {
    margin-top: 10px;
}

.medius-grid .grid-cell select {
    font-size: 12px;
}

.medius-grid .grid-cell input[type='radio'],
.medius-grid .grid-cell input[type='checkbox'] {
    margin-top: 0;
}

.medius-grid .grid-row.draggable {
    cursor: move;
}

.medius-grid-drag-tooltip {
    font-family: "Source Sans Pro";
    font-size: 14px;
    border: 1px solid #333333;
    background: #fff;
    padding: 3px;
    z-index: 10000;
}

/* smartphones */
@media (max-width: 767px) {

    .medius-grid > section {
        border: 0;
    }

    .medius-grid > section > footer {
        border-top: 0;
    }

    .medius-grid .grid,
    .medius-grid .grid > article {
        display: block;
        width: auto !important;
    }

    .medius-grid .grid > aside,
    .medius-grid .grid > aside > div,
    .medius-grid .grid > header {
        display: none;
    }

    .medius-grid .grid-row {
        display: table;
        empty-cells: show;
        margin-bottom: 20px;
        border: 1px solid #dddddd;
        border-radius: 4px;
        width: 99%;
    }

    .medius-grid .multiselect {
        display: none;
    }

    .medius-grid .grid > article .grid-row > .grid-cell,
    .medius-grid .grid > article .grid-row:last-child > .grid-cell {
        display: table-row;
    }

    .medius-grid .grid-row > .grid-cell > section,
    .medius-grid .grid > article .grid-row > .grid-cell > header {
        display: table-cell;
        width: 50%;
        border-bottom: 1px solid #dddddd;
    }

    .medius-grid .grid > article .grid-row > .grid-cell > header {
        padding: 4px 5px;
        line-height: 18px;
        text-align: left;
        vertical-align: top;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        border-right: 1px solid #dddddd;
    }

    .medius-grid .grid > article .grid-row > .grid-cell:last-child > *,
    .medius-grid .viewport-bigger .grid-row > .grid-cell:last-child > * {
        border-bottom: 0;
    }



    .medius-grid .grid > article .grid-row > .grid-cell.multiselect {
        display: none;
    }

}

.medius-grid .complex-row {
    background: #eee;
    border: solid #ccc 1px;
    padding: 5px;
    margin: 5px 0;
    border-radius: 4px;
}

.medius-grid .complex-row label {
    margin: 3px 0 2px 0;
}

.columnPickerDropdownContainer{
    width: 250px;
    z-index: 1110 !important;

    &__columnChecklist {
        max-height: 210px;
        overflow-y: auto;
    }
}

.columnPickerButtonContainer{
    margin-bottom: 8px;
}