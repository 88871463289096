.administration-favourite-link {
    &__roles-select-list {
        display: flex;
        width: 50%;
        margin-bottom: 20px;
    }

    &__roles-autocompleter {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-right: 20px;
    }

    &__roles-add {
        display: flex;
        flex-direction: column;
        width: 20%;
        height: 50px;
        min-width: 70px;
    }

    &__roles-table {
        width: 100%;
    }
}