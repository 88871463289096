.importConfiguration {
    padding: 0 20px;
    height: 100%;
    display: flex;

    &__splitter {
        border: 0;
    }

    &__right-side {
        height: 100%;
        padding: 0 20px;

        &__header {
            border-bottom: 1px solid gray;
            margin-bottom: 5px;
        }
    }

    &__gridPanel {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__toolbar-label {
        margin: 0 10px;
    }

    &__configuration-property {
        width: 50%;
        position: relative;

        display: block;
        margin-bottom: 20px;

        span.k-textbox-container {
            width: 100%;
        }        
    }

    &__configuration-field-property {
        width: 50%;
        position: relative;

        display: block;
        margin-bottom: 20px;

        input[type="radio"][disabled], input[type="checkbox"][disabled], input[type="radio"][readonly], input[type="checkbox"][readonly] {
            background-color: #17BADE;
        }
    }

    &__configuration-field-header {
        margin-bottom: 0px;
    }

    &__itemsGrid {
        font-size: 12px;
        overflow-y: hidden;
        cursor: pointer;
        
        table {
            width: 100% !important;
        }

        .k-pager-info {
            display: flex!important;
        }

        
    }

    .required .k-label:before {
        content: "* ";
        color: red;
    }
    
}