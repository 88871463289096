.edit-component{
    display: inline-block;
    position: relative;
    width: 99%;
}

.edit-component input[type=text],
.edit-component input[type=password],
.edit-component input[type=email],
.edit-component .input-append {
    min-height: 30px;
    margin-bottom: 0;
}

.edit-component input,
.edit-component textarea {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.edit-component.editors-bool-checkbox {
    text-align: center;
}

.edit-component.editors-bool-checkbox input[type=checkbox] {
    width: auto;
}

.edit-component textarea {
    resize: none;
}

.edit-component.align-right input,
.edit-component.align-right textarea {
    text-align: right;
}

.edit-component .indicator {
    display: block;
    position: absolute;
    top: 2px;
    right: 2px;
    background: #ffffff;
}

.edit-component .fsize14{
    font-size: 14px;
}

.edit-component .indicator.inline-static {
    display: inline;
    position: static;
}

.edit-component.align-right .indicator {
    left: 2px;
}

.edit-component .indicator.validation, 
.edit-component .indicator.side-validation,
.edit-component .indicator.confidence {
    display: none;
    cursor: pointer;
    width: 14px;
    height: 14px;
}

.edit-component.invalid .indicator.validation, 
.edit-component.invalid .indicator.side-validation,
.edit-component .indicator.confidence {
    display: block;
}

.edit-component.invalid input {
  border-color: #e9322d;
  -webkit-box-shadow: 0 0 6px #f8b9b7;
     -moz-box-shadow: 0 0 6px #f8b9b7;
          box-shadow: 0 0 6px #f8b9b7;
}

.edit-component .indicator.validation {
    padding: 1px 5px 9px;
}

.edit-component .indicator.confidence {
    padding-top: 3px;
}

.edit-component .indicator.side-validation {
    right: -18px;
    background: transparent;
}

.edit-component .indicator.loader {
    background: url('images/icon-loading.gif') no-repeat center center;
    width: 24px;
    height: 24px;
}

.edit-component .indicator.required {
    color: #ff0000;
    top: 0;
    width: 5px;
    height: 24px;
    background: transparent;
}
.edit-component.align-right .indicator.required,
.edit-component .indicator.required {
    right: -6px;
    left: auto;
}

.edit-component .hidden-required ~ .indicator.required {
    left: auto;
    right: 5px;
}

.edit-component.align-right .hidden-required ~ .indicator.required
{
     left: 3px;
}

.edit-component .indicator.remove {
    top: 5px;
    right: 5px;
    cursor: pointer;
    height: 14px;
    opacity: 0.3;
}

.editors-bool-toggle button {
    min-width: 50px;
}

.edit-component.editors-bool-default .btn.active.disabled {
    background-color: var(--md-color-brand-primary-base);
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
}

.edit-component.numeric-no-spin input[type=number]::-webkit-inner-spin-button,
.edit-component.numeric-no-spin input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.edit-component.autocompleter input[type=text] {
    margin-bottom: 0;
}

.suggestions-above {
    position: relative;
}

.suggestions-above > .edit-component {
    width: 250px;
}

.suggestions-above .edit-autocompleter-suggestions {
    top: auto !important;
    bottom: 100%;
    margin-bottom: 5px;
}

.edit-autocompleter-suggestions {
    position: absolute;
    background-color: #ffffff;
    color: #333333;
    padding: 4px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
    display: block;
}

/*Has to be bigger z-index than tooltip and tooltip has it very big*/
.edit-autocompleter-suggestions:hover {
    z-index: 100000000;
}

.edit-autocompleter-suggestions .noresult,
.edit-autocompleter-suggestions .item {
    margin: 0;
    display: block;
    min-width: 186px;
    max-width: 500px;
    line-height: 24px;
    padding: 3px 22px 3px 3px;
}

.edit-autocompleter-suggestions .item.selected, 
.edit-autocompleter-suggestions .item.selected:hover {
    background-color: var(--md-color-brand-primary-base);
    color: #f0f0f0;
}

.edit-autocompleter-suggestions .item:hover {
    background-color: #afd7eb;
    color: #0f0f0f;
    cursor: pointer;
}

.edit-autocompleter-suggestions .item p {
    margin: 0 0 0 0;
}

.edit-autocompleter-suggestions .item:not(.selected) .description {
    color: #999999;
}

.medius-grid .edit-autocompleter-suggestions,
.modal .edit-autocompleter-suggestions,
.modal-scrollable .edit-autocompleter-suggestions {
    position: fixed;
}

.edit-component-unit {
  padding: 3px 5px 0px 3px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  width: 50px;
  min-height: 30px;
}

.limitted-width-30
{   
    width:30px;
}

.limitted-width-40
{   
    width:40px;
}

.limitted-width-40-percent
{   
    width: 40%;
}

.limitted-width-60-percent
{   
    width: 60%;
}

.limitted-width-80-percent
{   
    width: 80%;
}

.limitted-width-20-percent
{   
    width: 20%;
}

.limitted-width-50
{   
    width:50px;
}

.limitted-width-60
{   
    width:60px;
}

.limitted-width-70
{   
    width:70px;
}

.calc-width-100-50 {
    width: calc(100% - 50px);
    width: -webkit-calc(100% - 50px);
    width: -moz-calc(100% - 50px);
}

.calc-width-100-60 {
    width: calc(100% - 60px);
    width: -webkit-calc(100% - 60px);
    width: -moz-calc(100% - 60px);
}

.calc-width-100-10 {
    width: calc(100% - 10px);
    width: -webkit-calc(100% - 10px);
    width: -moz-calc(100% - 10px);
}

.calc-width-100-30 {
    width: calc(100% - 30px);
    width: -webkit-calc(100% - 30px);
    width: -moz-calc(100% - 30px);
}

.calc-width-100-25 {
    width: calc(100% - 25px);
    width: -webkit-calc(100% - 25px);
    width: -moz-calc(100% - 25px);
}

.calc-width-100-28 {
    width: calc(100% - 28px);
    width: -webkit-calc(100% - 28px);
    width: -moz-calc(100% - 28px);
}

.edit-component .finance-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 2px;
    margin-right: 2px;
    font-size: 12px;
}

.edit-component .finance-btn {
    padding: 4px 4px 4px 0px;
}

.edit-component .btn .finance-ceret {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 1;
}

.edit-component.document-search-query {
    margin: 0;
}

.edit-component.document-search-query > * {
    display: inline-block;
    vertical-align: top;
}

.edit-component.document-search-query > select {
    width: 150px;
    margin-right: 5px;
}

.edit-component.document-search-query > input {
    width: calc(100% - 185px);
    width: -webkit-calc(100% - 185px);
}

.edit-component.document-search-query > div {
    width: 20px;
    text-align: center;
    padding-top: 5px;
}

.edit-component.document-search-query .styled-hint,
.column-selector-component .styled-hint {
    font-style: italic;
}

.column-selector-component .not-available {
    cursor: not-allowed;
    opacity: 0.5;
}

.column-selector-component li > .dropdown-menu {
    top: 0;
    bottom: auto;
}

.edit-component .ui-button-false.btn.active {
    background-color: var(--md-color-brand-primary-base);
    color: white;
}

.edit-component .ui-button-true.btn.active {
    background-color: var(--md-color-brand-primary-base);
    color: white;
    text-shadow: none;
}

.edit-component .ui-button-toggle.btn.active {
    background-color: var(--md-color-brand-primary-base);
    color: white;
    text-shadow: none;
}

/*labels drop down editor*/

.labels-dropdown-item .dropdown {
    margin-top: 20px;
}

.labels-dropdown-item {
    height: 22px;
    cursor: pointer;
    overflow: visible;
    position: relative;
    display: block;
    border-top: 1px solid transparent;
}

.labels-dropdowns .btn-group > .dropdown-menu {
    font-size: 10.5px;
}

.labels-dropdowns .color-box {
   display: inline-block;
   border-radius: 3px;
   width: 17px;
   height: 16px;
   position: relative;
   top: 3px;
}

.labels-dropdowns .color-box > i.icon-ok {
    vertical-align: top;
    margin-top: 1px;
    margin-left: 1px;
}

.labels-dropdowns .label-description {
    margin-left: 5px;
    text-shadow: none;
}

.labels-dropdowns .label{
    height: 14px;
}

.labels-dropdown {
    position: unset;
}

.edit-component .btn-group .btn .caret {
    position: absolute;
    right: 3px;
    top: 4px;
}

.edit-component .calc-width-100-105{
    width: calc(100% - 105px);
    width: -webkit-calc(100% - 105px);
    width: -moz-calc(100% - 105px);
}

.edit-component .calc-width-100-74{
    width: calc(100% - 74px);
    width: -webkit-calc(100% - 74px);
    width: -moz-calc(100% - 74px);
}

.edit-component .dropdown-menu > li > a .display-component .translationValue {
    font-weight: bold;
    margin-left: 10px;
}

.edit-component .dropdown-menu > li > a > div {
    display: inline-block;
    width: 40px;
}

.edit-component .dropdown-menu > li .translation-value{
    max-width: 240px;
    min-width: 140px;
    vertical-align: top;
}

.edit-component {
    .comment-text-area {
        margin-bottom: 10px;
    }
}