@mixin noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome and Opera */
}

@media screen and (max-width: 1325px) {
    #top-bar-icon-name { display: none; } 
}

$top-bar-height: 50px;
$top-bar-background-color: #333333;

.top-bar {
    font-family: 'Source Sans Pro';
    background-color: $top-bar-background-color;
    height: $top-bar-height;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    &__qa-note {
        margin-left: 8px;
        margin-right: 8px;
    }

    &__pending-exports {
        outline: 0;
    }

    &__pending-exports:focus {
        outline: 0;
    }

    &__wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #1D2833;
    }

    &__left_side{
        width: 100%;
        display: flex;
        align-items: center;
    }

    &__right_side{
        font-family: "Source Sans Pro";
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0px 10px;
    }

    &__right_side > * {
        padding: 0px 10px;
    }
}

.top-bar-logo {
    display: inline-flex;
    align-items: center;

    &__image {
        max-width: 24px;
        height: 24px;
    }

    &:hover{
        text-decoration: none;
    }

    &:focus{
        text-decoration: none;
    }
}

.top-bar-header-text{
    font-size: 18px;
    font-weight: 600;
    margin-left: 12px;
    margin-right: 8px;
    color:#000;
}

.top-bar-icon-holder:hover .top-bar__icon{
    fill: #333333;
}

.top-bar-icon-holder--active .top-bar__icon{
    fill: #1D2833 !important;
}

.top-bar-icon-holder:hover .top-bar-icon-name{
    color: #333333;
    text-decoration: none;
}

.top-bar-icon-holder--active .top-bar-icon-name{
    color: #1D2833 !important;
    text-decoration: none;
}

.top-bar-icon-holder{
    display: flex;
    text-decoration: none;
    margin-top: 5px;
    margin-right: 8px;
    margin-bottom: 5px;
    padding: 4px 8px;
    border-radius: 3px;
    cursor: pointer;

    &:hover{
        background-color: #CBCBCB;
        color: #333333;
        text-decoration: none;
    }

    &:focus{
        text-decoration: none;
    }

    &--active{
        background-color: white;
    }

    &--extended{
        fill: #FFF !important;
    }
}

.top-bar__icon_container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-bar__icon {
    vertical-align: middle;
    fill: #BBB;
    font-size: 24px;
    width: 24px;
    height: 24px;
    position: relative;
    @include noSelect;
    cursor: pointer;

    &--active {
        fill: #1D2833;
    }
}

$top-bar-menu-list-padding: 7.5px;

.top-bar-icon-holder--expanded .top-bar-icon-name {
    color: #FFF !important;
    text-decoration: none;
}

.top-bar-icon-holder--expanded .top-bar__icon {
    fill: #FFF !important;
}

.top-bar-menu {
    position: relative;

    &__list {
        position: absolute;
        top: 36px;
        z-index: 1000;
        min-width: 160px;
        padding: $top-bar-menu-list-padding;
        list-style: none;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
        background-clip: padding-box;
        border: none;
        border-top: none;

        &--below-initials-circle {
            right: 8px;
            top: 42px;
        }

        &--left-aligned {
            margin: 10px 0 0;
        }

        &--right-aligned {
            right: 0;
            top: 41px;
        }

        &-link {
            display: block;
            padding: 8px;
            clear: both;
            font-weight: normal;
            line-height: 20px;
            color: #333;
            white-space: nowrap;
            text-decoration: none;
            @include noSelect;
        }

        &-link:hover {
            text-decoration: none;
            background-color: #E6E6E6;
            color: #000;
        }

        &-divider {
            height: 1px;
            margin-top: 4px;
            margin-bottom: 4px;
            margin-left: -$top-bar-menu-list-padding;
            margin-right: -$top-bar-menu-list-padding;
            overflow: hidden;
            background-color: #e5e5e5;
            border-bottom: 1px solid #fff;
        }
    }
}

.top-bar-initials {
    cursor: pointer;
    margin-right: 0;

    &__circle {
        margin-left: 8px;
        margin-right: 9px;
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #0488cb;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        @include noSelect;

        &:hover {
            background-color: #0376B0;
        }
    }
}

.top-bar-header{
    padding-left: 3px;
    display: flex;
    background-color: white;
    height: $top-bar-height;
    box-sizing: border-box;
    border-bottom: solid 1px #1D2833;
}

.top-bar-app-drawer{
    display: flex;
}

.top-bar-triangle {
    border-left: 13px solid #fff;
    border-bottom: $top-bar-height solid #1d2833;
    margin-right: 12px;
}

.top-bar-icon-name{
    margin-left: 4px;
    color: #fff;
    font-weight: 600;
    margin-top: 3px;
    margin-bottom: 3px;
}