.document-management {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__dialog {
        
        &__message {
            max-width: 500px;

            &__orderbased {
                background: #EBEBEB;
            }

            &__delete {
                margin:25px;
                text-align: center;
            }
        }
    }

    &__action-cell{
        display: flex;
        justify-content: center;

        &__button {
            margin-right: 5px;
        }
    }
}