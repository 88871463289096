#dashboard-framework .dashboard-list {
    margin-bottom: 0;
    border-bottom: 0;
    flex-basis: 0;
    -webkit-flex-basis:0;
    flex-grow: 1;
    -webkit-flex-grow: 1;
}

#dashboard-framework .dashboard-header {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    -webkit-flex-wrap: wrap-reverse;
}

#dashboard-framework .document-identifier {
    margin-bottom: 10px;
    margin-top: 9px
}

#dashboard-container , 
#dashboard-framework {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
}

#dashboard-framework {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
}

#dashboard-framework  #context-notification .back-container {
    width: 45px; 
    float: left; 
    padding-top: 3px;
}

#dashboard-framework  #context-notification .message-container {
    float: left;
}

#dashboard-framework .nav {
    min-height: 33px;
}

#dashboard-framework .tab-content {
    flex: 1;
    -webkit-flex: 1;
}

#dashboard-framework.context-notification-enabled .tab-content {
    top: 108px;
}

#dashboard-framework .dashboard {
    display: none;
    overflow: auto;
    height: 100%;
}

#dashboard-framework .dashboard.active 
{
    display: block;
}

.dashboard-buttons 
{
    display: flex;
    margin-top: 0;
    margin-bottom: 10px;
    align-self: auto;
}

#dashboard-framework .dashboard-layout
{
    text-align: center;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}
    
#dashboard-framework .dashboard-layout li.column 
{
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    margin: 0 5px;
    padding: 5px;
    width: 350px;
    min-height: 98%;
    border: 1px dashed #e0e0e0;
    text-align: left;
    *display: inline;
    flex-grow: 1;
    -webkit-flex-grow: 1;
}

#dashboard-framework .dashboard-framework-alert 
{
    margin: auto;
}

.dashboard-library ul 
{
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: visible;
}

.dashboard-library ul li 
{
    display: inline-block;
    vertical-align: top;
    float: left;
    width: 145px;
    height: 145px;
    padding: 5px;
    border: 1px solid #d0d0d0;
    margin: 0 5px 5px 0;
    cursor: pointer;
    overflow: hidden;
}

.dashboard-library ul li:hover
{
    border-color: #000000;
}

.dashboard-library ul li .gadget-title
{
   font-weight: bold;
}

.dashboard-library ul li .gadget-desc
{
    font-size: 12px;
    margin: 5px 0;
}

#dashboard-framework .modal.dashboard-rename
{
    width: auto;
}
      
#dashboard-framework .gadget 
{
    display: block;
    background: #ffffff;
    border: 1px solid #000000;
    padding: 5px;
    width: auto;
    min-height: 29px;
    margin: 0 0 10px 0;
}
    
#dashboard-framework .gadget .gadget-header 
{
    position: relative;
    font-weight: bold;
    background: #f0f0f0;
    height: 29px;
    cursor: move;
}

#dashboard-framework .gadget .gadget-title 
{ 
    padding: 5px;
}

#dashboard-framework .gadget .gadget-sub-title {
    font-weight: bold;
}

#dashboard-framework .gadget .gadget-buttons 
{
    position: absolute;
    display: block;
    right: 2px;
    top: 4px;
    width: 50%;
    height: 23px;
    text-align: right;
}

#dashboard-framework .gadget .gadget-buttons a
{
    margin: 0 1px;
}

#dashboard-framework .gadget .gadget-content
{
    min-height: 150px;
    margin-top: 5px;
}

#dashboard-framework .gadget .gadget-not-available-info 
{
    min-height: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
}

#dashboard-framework .gadget .gadget-content table 
{
    font-size: 12px;
}

#dashboard-framework .gadget .gadget-settings 
{
    padding: 5px;
}

#dashboard-framework .gadget .gadget-settings .edit-component input,
#dashboard-framework .gadget .gadget-settings .edit-component textarea {
    width: 206px;
}

#dashboard-framework .gadget-settings .form-horizontal .control-group {
    margin-bottom: 10px;
}

#dashboard-framework .gadget-view {
    position: relative;
}