.copy-requisition {
    &__error-container {
        height: 300px;
        overflow-y: auto;
        width: 100%;
    }

    &__row-icon-container {
        width: var(--md-size-icon-small);        
        display: flex;
        align-items: center;
        justify-content: center;
    }
}