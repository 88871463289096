.master-data-reindex {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    height: 100%;

    input[type="radio"] {
        width: 16px;
    }

    &__header {
        border-bottom: 1px solid gray;
        margin-bottom: 5px;
    }

    &__company-value {
        display: flex;
        flex-direction: row;
        padding-bottom: 5px;

        &__label {
            width: 250px;
        }
    }

    &__reindex {
        margin-top: 10px;

        &__row {
            display: flex;
            flex-direction: row;
            align-self: center;
            padding-bottom: 5px;
            
            &__label {
                width: 250px;
            }
        }
    }

    &__right-side {
        height: 100%;
        margin: 0 15px 0 20px;
        display: flex;
        flex-direction: column;
    }

    &__left-side {
        margin: 0 15px 0 20px;

        &__top-bar {
            display: flex;
            flex-direction: row;
            margin-bottom: 5px;
            justify-content: space-between;
            align-items: center;

            &__title {
                display: inline-flex;
                align-items: center;

                &__text {
                    margin-left: 50px;
                    color: black;
                }
            }
        }
    }
}

.master-data-reindex-importLogs {
    height: 100%;
    width: 100%;
    overflow-y: auto;

    &__card {
        width: 100%;
        margin-top: 10px;
        box-sizing: border-box;
    }

    &__card-title {
        margin-bottom: 4px;
        font-size: 14px;
    }

    &__card-footer {
        text-align: right;
    }
}
