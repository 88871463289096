.halflings {
    font-size: 12px;
}

.btn-halflings-small {
    height: 24px;
    width: 36px;
    padding: 0;
}

.btn-halflings-mini {
    height: 20px;
    width: 22px;
    padding: 0;
    line-height: 14px;
    font-size: 12px;
}

.halflings-icon {
   line-height: 16px;
}