/* all classes with -old suffix will be deleted with custom branding feature toogle */
body {
    margin: 0;
    font-family: "Source Sans Pro";
	font-size: 14px;
}

.bootstrap-legacy-input {
    padding: 4px 6px;
    margin-bottom: 10px;
	-webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px ;
    height: 20px;
}

.bootstrap-legacy-textarea {
    padding: 4px 6px;
    margin-bottom: 10px;
	-webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px ;
}

.card {
    padding: 32px;
    background: #fff;
    border-radius: 8px;
    margin: 1rem;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    box-sizing: border-box;
    width: 400px;
    z-index: 1;
}

.card-old {
    padding: 32px;
    background: #fff;
    border-radius: 2px;
    margin: 1rem;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    box-sizing: border-box;
    width: 400px;
}

.card-input {
    padding-left: 10px;
    box-sizing: border-box;
    border: solid 1px #979797;
    border-radius: 3px;
    height: 49px;
    margin-bottom: 17px;
    font-size: 16px;
    color: #454f5b;
    font-family: 'Source Sans Pro';
}

.card-input-old {
    box-sizing: border-box;
    border: solid 1px #999;
    border-radius: 2px;
    height: 30px;
    margin-bottom: 10px;
    padding-left: 6px;
}

.card-input-old:focus {
    outline: none;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.33);
}

.card-input:focus {
    outline: none;
    border-color: rgba(82, 168, 236, 0.8);
}

.card-label-old {
    font-size: 14px;
    margin-bottom: 5px;
    cursor: pointer;
}

.card-label {
    color: #454f5b;
    font-size: 16px;
    margin-bottom: 4px;
    cursor: pointer;
}

.card-password-container {
    display: flex;
    justify-content: space-between;
}

.card-button {
    border-radius: 3px !important;
    background: var(--md-color-brand-primary-base);
    border: none;
    border-radius: 2px;
    margin-top: 14px;
    color: #FFF;
    cursor: pointer;
    padding: 8px;
    font-size: 16px;
    width: 100%;
    height: 49px;
    font-family: 'Source Sans Pro';
}

.card-button:hover {
    background: #00adff;
}

.card-button-old {
    background: #19bade;
    border: none;
    border-radius: 2px;
    margin-top: 8px;
    color: #FFF;
    cursor: pointer;
    padding: 8px;
    font-size: 14px;
    width: 100%;
}

.card-button-old:hover {
    background: #109dbd;
}

.card-text-container {
    text-align: center;
    margin-top: 15px;
    font-size: 15px;
}

.card-text {
    cursor: pointer;
}

.card-text:hover {
    color: grey;
}

.card-text-container-old {
    color: grey;
    text-align: center;
    margin-top: 15px;
    font-size: 15px;
}

.card-text-old {
    cursor: pointer;
}

.card-text-old:hover {
    color: #1e2933;
}

.card-forgot-password-text {
    color: rgb(78, 168, 243);
}

.app {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.branding-background {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

.branding-logo-container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 0 24px 0;
}

.branding-logo-container-old {
    display: flex;
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid #e8e8e8;
    padding: 0 0 24px 0;
}

.branding-logo {
    max-width: 249px;
    max-height: 43px;
}

.branding-powered-by {
    position: fixed;
    right: 30px;
    bottom: 5px;
}

.branding-powered-by-img {
    width: 200px;
}

.card-content {
    text-align: center;
    font-size: 24px;
    flex-direction: column;
}

.card-message {
    max-width: 313px;
    margin-top: 27px;
    margin-bottom: 38px;
    line-height: 1.2;
    display: inline-block;
}

.collapsible-header {
    border-color: var(--md-color-card-border);
    background-color: var(--md-color-card-background);
    color: var(--md-color-text-primary);
    border-width: 1px;
    padding: 11px 14px;
}

.accordion-heading .accordion-toggle {
    padding: 0px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.accordion-toggle {
    margin-bottom: 0px;
}

.accordion-group {
    margin-bottom: 5px;
    background-color: var(--md-color-card-background);
    border: 1px solid var(--md-color-card-border);
}

.accordion-inner {
    padding: 9px 15px;
    border-top: 1px solid #e5e5e5;
}

.accordion-heading {
    border-bottom: 0;
    cursor: pointer;
}