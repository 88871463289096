.drawer-apps {
    background-color: white;
    min-width: 242px;
}

.drawer-wrapper {
    z-index: 1000; /* Higher than #administration-sidebar = 999  */
}

.drawer-wrapper .popup-content{
    border-top-width: 0px;
    background-color: white;
}

.drawer-app {
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    height: 48px;
    border-top: solid 1px #E6E6E6;

    &:hover {
        background-color: #E6E6E6;
    }
}

.drawer-app-img {
    width: 32px;
    height: 32px;
    padding: 8px;
}

.drawer-app-name {
    font-family: 'Source Sans Pro';
}

.drawer-app-href{
    text-decoration: none!important;
    color: #000;

    &:hover {
        color: #000;
    }
}

.top-bar__icon_drawer {
    cursor: pointer;
    fill: #1D2833;
    width: 32px;
    height: 32px;

    &__hover{
        fill: white;
    }
}

.top-bar__icon_padding{
    margin: 9px;
    border-radius: 6px;
    display: flex;
}

.top-bar__icon_padding_closed:hover{
    filter: invert(1);
}

.top-bar__icon_container{
    display: flex;
    border-radius: 6px;
    cursor: pointer;
}

.drawer-icon-backgroud {
    width: 50px;
    height: 50px;
    background-color: white;
    box-sizing: border-box;
    border-bottom: solid 1px #1D2833;
}

.drawer-wrapper.k-animation-container > .k-popup {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.drawer-wrapper.k-animation-container {
    margin-top: 9px;
}