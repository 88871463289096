.card-component {
    font-family: 'Source Sans Pro';

    &__page-container {
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        &__card {
            padding: 23px;
            background: #fff;
            border-radius: 7px;
            margin: 1rem;
            box-shadow: 1px 1px 9px 2px rgba(0, 0, 0, 0.5);
            box-sizing: border-box;
            width: 442px;
            z-index: 1;

            &__logo-container {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 12px;
    
                img {
                    max-width: 360px;
                    max-height: 60px;
                }
            }
        }
    }
    
    &__powered-by {
        position: fixed;
        right: 30px;
        bottom: 5px;

        img {
            width: 200px;
        }
    }
}