@use "sass:color";

.quick-search {
    margin-top: 0;
    width: 248px;
    height: 32px;
    margin-right: 2px;
}

.search-icon{
    width: 18px;
    height: 18px;
    position: absolute;
    left: 6px;
    top: 7px;
    opacity: 0.56;
    display: flex;
}

input.quick-search-term {
    padding: 6px;
    padding-left: 30px;
    margin-bottom: 0;
    font-family: "Source Sans Pro";
    font-size: 15px;
    font-style: italic;
    font-weight: normal;
    
    line-height: 1;    
    border-radius: 0px;

    background-color: #eeeeee;
    border: 0;

    &::-webkit-input-placeholder {
        color:rgba(0,0,0,0.56);
    }

    :-moz-placeholder {
        color: #fff;
    }

    :-ms-input-placeholder {
        color: #fff;
    }

    &--active {
        background-color: white;
        color: #808080;

        &::-webkit-input-placeholder {
            color: #808080;
        }

        :-moz-placeholder {
            color: #808080;
        }

        :-ms-input-placeholder {
            color: #808080;
        }
    }
}

.quick-search-term__loading-icon {
    background: url('images/icon-loading.gif') no-repeat;
    width: 16px;
    height: 20px;
    position: absolute;
    right: 8px;
    top: 8px;
}

.quick-search-suggestions {
    &.dropdown-menu {        
        margin-top: 2px;
        color: #333333;
        padding: 0;
        z-index: 9999999;
        display: block; /* Overrides Bootstrap styling thst hides dropdown lists by default */
        right: inherit;
        border: none;
        border-radius: 2px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }

    &__header {
        background-color: #f6f6f6;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        padding: 6px;
        border-radius: 2px 2px 0px 0px;
        font-size: 12px;
        color: #656565;
    }

    &__list {
        width: 270px;
        border: 0px;
        border-radius: 2px;
        font-size: 12px;
        margin: 0;
        list-style: none;
    }
}

.quick-search-suggestion {
    padding: 6px;
    cursor: pointer;
    line-height: 17px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    $selected-suggestion-background: #D1F1F8;

    &.selected {
        background: $selected-suggestion-background;        

        &:hover {
            background: color.adjust($selected-suggestion-background, $lightness: -10%);        
        }
    }

    &:last-child {
        border-bottom: none;
        border-radius: 0px 0px 2px 2px ;
    }

    &:hover {
        background: #ebebeb;
    }

    &__position {
        color: #696969;
        text-overflow: ellipsis;
        overflow:hidden;
        white-space:nowrap;

        &--emphasized {
            color: #0f0f0f;
            font-weight: 600;
        }
    }

    &__highlighted {
        background-color: yellow;
    }
}

.quick-search-no-results {
    margin-top: 3px;
    border-radius: 2px;

    &.dropdown-menu {
        width: 270px;
        display: block;
        border: 0;
        margin-left: 1px;
        font-size: 11px;
        right: inherit;
    }

    &__item {
        display: block;
        font-weight: bold;
        padding: 3px 3px 3px 13px;
    }
}

.quick-search-item-link:hover {
    text-decoration: none;
}