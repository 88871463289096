@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}
  
@mixin animation($str) {
    animation: #{$str};   
    -webkit-animation: #{$str};       
}

@include keyframes(loader-delay) {
    0%, 40%, 100% { 
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }  20% { 
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}

.loader-component {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__text {
        font-size: 36px;
        color: rgba(255,255,255,09);
    }

    &__spinner {
        margin-top: 32px;
        width: 50px;
        height: 40px;
        text-align: center;
        font-size: 10px;
      
        > div {
            background-color: rgba(255,255,255,0.87);
            height: 100%;
            width: 6px;
            display: inline-block;
            @include animation(loader-delay 1.2s infinite ease-in-out);
            margin-left: 2px;
        }
      
        > .loader_rectangle2 {
            -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
        }
        
        > .loader_rectangle3 {
            -webkit-animation-delay: -1.0s;
            animation-delay: -1.0s;
        }
        
        > .loader_rectangle4 {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        }
        
        > .loader_rectangle5 {
            -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
        }
    }
}