.importManagement {
    padding: 0 20px;
    height: 100%;
    display: flex;

    &__splitter {
        border: 0;
    }

    &__right-side {
        height: 100%;
    }

    &__gridPanel {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__toolbar-label {
        margin: 0 10px;
    }

    &__delete-button {
        background: #f43a30;
        color: white;

        &:hover {
            background: #f43a30;
            color: white;
        }
    }

    &__toolbar-searchboxWrapper{
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__infoPanel {
        height: 100%;
        padding: 10px;
        box-sizing: border-box;

        &__panels {
            height: 100%;
        }

        &__panel {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden !important;

            >*:first-child,>*>*:first-child,>*>*>*:first-child {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
            }
            
        }
    }

    &__queueItemsGrid {
        font-size: 12px;
        overflow-y: hidden;
        cursor: pointer;
    }
}