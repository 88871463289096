.document-summary {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
}

.document-summary__section {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin: 0 10px 0 10px;
    min-width: 0;
}

.document-summary__section__identifiers {
    display: flex;
    font-size: 12px;
    margin-bottom: 4px;
    min-width: 0;
}

.document-summary__column {
    margin-top: 5px;
}

.document-summary__column--wrapped {
    overflow: hidden;
}


.document-summary__icon {
    font-size: 16px;
    color: #666666;
    align-self: center;
}

.document-summary__icon.glyphicons:before {
    padding: 4px 8px 1px 8px;
}

.document-summary__key {
    margin-right: 10px;
    color: #666666;
    line-height: 14px;
    white-space: nowrap;
    margin-bottom: 2px;
}

.document-summary__value {
    margin-right: 10px;
    line-height: 14px;
    height: 14px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    font-weight: bold;
    margin-bottom: 2px;
}