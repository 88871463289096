.optionsDropdownContainer{
    width: 250px;
    max-height: 600px;
    overflow-y: auto;
}

.searchForm{
    margin-bottom: 0px;

    input[type=text] {
        margin-bottom: 0px;
        margin-right: 4px;
        height: auto;
    }
}

.saved-filter{
    button {
        max-width: 160px;
        span {
            max-width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}