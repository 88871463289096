.inbox-search-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
}

.inbox-search-header-title {
    color: #888888;
    font-weight: 700;
    white-space: nowrap;
    margin-left: 0.3em;
    margin-right: 0.3em;
}

.inbox-search-header::after {
    width: 100%;
    content: '';
}

.inbox-search-header-pageline-right {
    height: 1px;
    width: 100%;
    background-color: #EEE;
    flex-grow: 1;
}

.inbox-search-header-pageline-left {
    height: 1px;
    width: 1em;
    background-color: #EEE;
    flex-grow: 1;
}

.inbox-search-header-with-pageline {
    display: flex;
    flex-direction: row;
    align-items: center;
}

