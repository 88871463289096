.editor-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.editor-body {
    flex: 1;
    min-height: 0;
    display:flex;
    flex-direction: column;
}

.tabBarContainer{
    margin-bottom: 8px;
    label{
        margin-bottom: 0px;
    }
}