/* Tooltip container */
.resultListTooltip {
  position: relative;
  display: inline-block;

  .resultListTooltiptext {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    position: absolute;

    width: 240px;
    bottom: 100%;
    left: 50%;
    margin-left: -120px;
  }

  &:hover {
    .resultListTooltiptext {
      visibility: visible;
    }
  }
}