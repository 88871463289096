@use "@medius/ui-controls/lib/_design-tokens.scss" as northstar;

$assistantMessageBackgroundColor: #e7f0f8;
$assistantMessageTextColor: #000;
$userMessageBackgroundColor: #0a4e8b;
$userMessageTextColor: #fff;
$messageBorderRadius: 4px;

.copilotComment{
    white-space: pre-wrap;

    &__mention {
        display: inline;
        position: relative;
        cursor: pointer;
        color: #0E70C6;
    }

    &__tooltip {
        position: absolute;
        bottom: 100%;
        left: 0%;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        white-space: nowrap;
        z-index: 100000;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease;

        &__title {
            font-weight: bold;
            color: black;
            display: block;
            margin-bottom: 4px;
        }

        &__description {
            font-size: 0.9em;
            color: gray;
        }

        &__dark {
            position: absolute;
            bottom: 75%;
            left: 100%;
            transform: translateX(10px);
            width: 300px;
            padding: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            z-index: 100000;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.2s ease;
            background-color: #333333;
    
            &.align-left {
                left: auto;
                right: 100%;
                transform: translateX(-10px);
            }
    
            &.align-right {
                left: 100%;
                transform: translateX(10px);
            }
    
            > span {
                font-weight: normal !important; 
                color: white;
                margin-bottom: 0px !important;
            }
        }
    }

    &__mention:hover &__tooltip {
        opacity: 1;
        pointer-events: auto;
    }

    &__translate__icon {
        position: relative;
        cursor: pointer;
    }

    &__translate__icon:hover &__tooltip__dark.align-left {
        opacity: 1;
        pointer-events: auto;
    }

    &__translate__icon:hover &__tooltip__dark.align-right {
        opacity: 1;
        pointer-events: auto;
    }

    &__presentationContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 10px;
    }

    &__content {
        word-break: break-word;
        overflow-wrap: break-word;
    }
}

.copilotCommentsButtons{
    &__buttons > button {
        margin: 5px 5px 10px 0 !important;
        padding: 0px 8px !important;
    }

    &__answerContainer{
        margin: 1px 1px 10px 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 0;
    }

    &__answerContainerInner{
        width: 100%;
        background: #fff;
        border-radius: 5px;
    }

    &__answerContainer::after{
        content: "";
        position: absolute;
        animation: animateBorder 4s linear infinite;
        background-image: linear-gradient(to right, #0E70C6, #EB14BC, #3FBE29, #0E70C6, #EB14BC);
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        z-index: -1;
        background-size: 400%;
        border-radius: 6px;
    }

    &__loader{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &__loader > svg{
        margin: 0 !important;
        background-color: #fff !important;
    }

    &__message{
        font-family: "Source Sans Pro";
        font-weight: 400;
        padding: 10px 8px 0px;
        border-radius: $messageBorderRadius;
        width: 100%;

        &__assistant {
            background-color: #fff;
        }
    }
}

.copilotInvoiceImageTooltip{
    &__anchor{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
    }

    &__canduMainContainer{
        position: absolute;
        bottom: 40px;
    }

    &__mainContainer{
        position: absolute;
        bottom: 40px;
        z-index: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 1px solid transparent;
        box-shadow: 0px 0px 3px 0px rgba(39, 41, 45, 0.06), 0px 0px 8px 0px rgba(18, 18, 18, 0.16);
    }

    &__mainContainer::after{
        position: absolute;
        animation: animateBorder 4s linear infinite;
        background-image: linear-gradient(to right, #0E70C6, #EB14BC, #3FBE29, #0E70C6, #EB14BC);
        top: -2px;
        bottom: -2px;
        left: -2px;
        right: -2px;
        z-index: -1;
        border-radius: 5px;
        background-size: 400%;
        content: "";
    }

    &__buttonsContainer{
        display: flex;
        gap: 8px;
        padding: 8px;
        background: #FFFEFE;
        border-radius: 4px;
    }

    &__buttonsContainer > button{
        border-radius: 4px;
        border: 1px solid var(--Color-common-Brand-Primary-Base, #0E70C6);
        color: var(--Color-common-Brand-Primary-Base, #0E70C6);
        background: #FFF;
        padding: 0px 8px;
    }

    &__closeButton{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.copilotNotificationWindow{
    margin-bottom: 100px;

    &__optionsMenuAnchor{
        position: relative;
    }
    
    &__confirmationDialogContainer{
        &__buttonsContainer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;

            &__rightSide > :first-child{
                margin-right: 10px;
            }
        }
    }

    &__optionsMenuContainer{
        position: absolute;
        right: 50%;
        bottom: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        padding: 0px;
        border-radius: 4px;
        box-shadow: 1px 0px 12px -5px rgb(66, 68, 90);   
    }

    &__optionsMenuContainer > button{
        width: 100%;
        outline: none;
        border: none;
        transition: 0.2s all linear;
        background-color: #fff;
        display: inline-block;
        white-space: nowrap;
        padding: 10px;
        text-align: start;
    }

    &__optionsMenuContainer > button:hover{
        background-color: #E7F0F8;
    }

    &__optionsMenuContainer > button:focus{
        outline: none;
    }
    
    &__anchor{
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    &__leftInnerAnchor{
        position: relative;
    }

    &__mainContainer{
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 300px;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 1px 0px 12px -5px rgb(66, 68, 90);
        outline: none;
        background-clip: padding-box;
    }

    &__mainContainer::after {
        position: absolute;
        animation: animateBorder 4s linear infinite;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to right, #0E70C6, #EB14BC, #3FBE29, #0E70C6, #EB14BC);
        bottom: -5px;
        z-index: -1;
        border-radius: 0px 0px 4px 4px;
        background-size: 400%;
        content: "";
    }

    &__topRow{
        display: flex;
        justify-content: space-between;
        padding: 15px 10px 0px 15px;

        &__titleContainer{
            display: flex;
            justify-content: space-around;
            align-items: center;
        }

        &__imageContainer{
            transform: translateY(5px) scale(1.7);
        }

        &__titleContainer > p{
            font-weight: 700;
            font-size: 16px;
            margin: 0px;
            margin-left: 10px;
        }

        &__buttonsContainer{
            display: flex;
        }

        &__button{
            cursor: pointer;
            margin: 0px 3px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__bottomRow{
        display: flex;
        padding: 5px 15px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        background: var(--Color-base-Grey-6, #EFEFEF);

        &__button{
            cursor: pointer;
            margin: 0px 3px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__button > p{
            margin: 0px 5px;
        }

        &__button.options{
            border-radius: 2px;
            color: var(--md-color-icon-brand);
        }
    }

    &__buttonsContainer{
        padding: 0px 10px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        align-content: flex-start;
        align-self: stretch;
        flex-wrap: wrap;

        &__loaderContainer{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }

    &__buttonsContainer > button {
        background-color: northstar.$md-color-button-primary-text-active;
    }

    &__buttonsContainer > button:hover {
        background-color: northstar.$md-color-shade-5;
    }

    &__button {
        position: relative;
        background-color: northstar.$md-color-background-light-grey;
        color: northstar.$md-color-brand-primary-base;
        padding: 3px 15px;
        font-family: northstar.$md-font-family-default;
        box-sizing: border-box;
        outline: none;
        border: 1px solid transparent;
        background-clip: padding-box;
        border-radius: 4px;
    
        span {
            font-size: northstar.$md-size-text-default;
            line-height: northstar.$md-font-lineheight-default;
            font-weight: 600;
            vertical-align: middle;
        }
    
        svg {
            width: 16px;
            margin-right: 4px;
            vertical-align: middle;
        }
    }
}

.copilotButton {
    position: relative;
    background-color: northstar.$md-color-background-light-grey;
    color: northstar.$md-color-brand-primary-base;
    padding: 3px 15px;
    font-family: northstar.$md-font-family-default;
    box-sizing: border-box;
    outline: none;
    border: 1px solid transparent;
    background-clip: padding-box;
    border-radius: 4px;

    span {
        font-size: northstar.$md-size-text-default;
        line-height: northstar.$md-font-lineheight-default;
        font-weight: 600;
        vertical-align: middle;
    }

    svg {
        width: 16px;
        margin-right: 4px;
        vertical-align: middle;
    }
}

.copilotButton.animate::after {
    position: absolute;
    animation: animateBorder 4s linear infinite;
    background-image: linear-gradient(to right, #0E70C6, #EB14BC, #3FBE29, #0E70C6, #EB14BC);
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    z-index: -1;
    border-radius: 4px;
    background-size: 400%;
    content: ''; 
}

.copilotButton:hover {
    background-color: northstar.$md-color-hover-brand;
}

@keyframes animateBorder {
    0% { background-position: 0% 0%; }
    100% { background-position: 100% 0%; }
}

.mediusAgent{
    width: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 4;

    &__widgetCard {
        background-color: northstar.$md-color-grey-0;
        margin: 8px 0px;
        padding: 8px;
        border-radius: $messageBorderRadius;

        &__iconAndContentContainer {
            display: inline-flex;
            width: 100%;

            &__icon {
                margin-right: 4px;
                margin-top: 2px;
            }
        }
    }
            
    &__messagesContainer{
        overflow: auto;
        scrollbar-width: thin;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    &__titleImage{
        width: 25px;
        height: 25px;
    }

    &__iconContainer{
        padding: 4px;
        background: linear-gradient(90deg, #0E70C6 0%, #0A4E8B 100%);
        border-radius: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &__iconContainer:hover{
        background: northstar.$md-color-blue-110;
    }

    &__iconContainer:focus{
        background: northstar.$md-color-blue-110;
    }

    &__icon{
        filter: invert(1);
    }

    &__streamErrorMessageContainer{
        background-color: northstar.$md-color-red-20;
        padding: 8px 8px 12px 8px;
        border-radius: 4px;
        
        &__link{
            margin: 0px 3px;
        }
    }

    &__timeoutErrorMessageContainer{
        background-color: #FCE4CC;
        padding: 8px 8px 12px 8px;
        border-radius: 4px;
        align-items: flex-start;
        width: auto !important;
        max-height: 70px;

        &__header{
            font-weight: bold;
        }

        &__icon{
            fill: #EF7A00;
            align-self: center;
            flex-shrink: 0;
        }
    }

    &__nextApproverErrorMessageContainer{
        background-color: #FCE4CC;
        padding: 8px 8px 12px 8px;
        border-radius: 4px;
        margin-bottom: 10px;

        &__header{
            font-weight: bold;
        }
    }

    &__innerContainer{
        display: flex;
        flex-direction: column;
        height: 100%;

        &__bottom{
            margin-top: auto;
            width: 100%;
            background: northstar.$md-color-page-background-default;
            position: relative;
        }

        &__loaderIconContainer{
            position: absolute;
            top: 50%;
            left: 50%;
            margin: 0px 12px;
        }
    }

    &__previousInvoiceDisabledReflinkContainer{
        display: flex;
        align-items: center;
    }

    &__previousInvoiceDisabledReflinkContainer > p{
        margin: 0px;
        margin-right: 3px;
    }

    &__welcomeWidgetContainer{
        background-color: $assistantMessageBackgroundColor;
        padding: 8px;
        border-radius: $messageBorderRadius;

        &__messageContainer{
            display: flex;
            margin-bottom: 10px;
        }

        &__iconContainer{
            height: 100%;
            margin-right: 8px;
            margin-top: 2px;
        }
    }

    &__nonOperationalWidgetContainer{
        background-color: northstar.$md-color-grey-3;
        padding: 8px;
        border-radius: $messageBorderRadius;

        &__messageContainer{
            display: flex;
            margin-bottom: 10px;
        }

        &__iconContainer{
            height: 100%;
            margin-right: 8px;
            margin-top: 2px;
        }
    }

    &__message{
        font-family: "Source Sans Pro";
        font-weight: 400;
        padding: 10px 8px 0px;
        border-radius: $messageBorderRadius;

        &__assistant {
            background-color: $assistantMessageBackgroundColor;

            &__footer {
                margin-bottom: 10px;
            }
        }

        &__user {
            background-color: $userMessageBackgroundColor;
            color: $userMessageTextColor;
            margin-left: auto;
        }

        &__textContainer {
            flex-grow: 0;
            max-width: 95%;

            &__markdown {
                &__assistant {
                    code {
                        background-color: $assistantMessageBackgroundColor;
                        color: $assistantMessageTextColor;
                    }
                }
                &__user {
                    code {
                        background-color: $userMessageBackgroundColor;
                        color: $userMessageTextColor;
                    }
                }
                code {
                    padding: 0;
                    border: none;
                    font-size: 14px;
                    font-style: italic;
                    font-weight: bold;
                }
                th {
                    border-bottom: solid 1px northstar.$md-color-grey-50;
                    padding: 
                        northstar.$md-size-table-headercell-padding-top-default
                        northstar.$md-size-table-headercell-padding-right-default
                        northstar.$md-size-table-headercell-padding-bottom-default
                        northstar.$md-size-table-headercell-padding-left-default;
                    line-height: northstar.$md-font-table-header-lineheight-default;
                    font-weight: northstar.$md-font-table-header-weight-default;
                    font-size: northstar.$md-size-table-headercell-text-default;
                    color: northstar.$md-color-text-primary;
                    text-align: left;
                }
                
                table {
                    display: block;
                    width: 100%;
                    max-width: 100%;
                    overflow-y: auto;
                    scrollbar-width: thin;
                    background-color: northstar.$md-color-grey-0;
                    margin: 8px 0px;
                    border-radius: 4px;
                }

                td {
                    border-bottom: solid 1px northstar.$md-color-grey-13;
                    padding: 
                        northstar.$md-size-table-rowcell-padding-top-default
                        northstar.$md-size-table-rowcell-padding-right-default
                        northstar.$md-size-table-rowcell-padding-bottom-default
                        northstar.$md-size-table-rowcell-padding-left-default;
                }
            }
        }

        &__widgetContainer {
            flex-grow: 1;
        }

        &__nsfwWarning{
            background-color: northstar.$md-color-error-background;
        }

        &__feedback{
            margin-top: 10px;    
            
            &__lower{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;

                &__votebuttons{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &__votebuttons > svg{
                    cursor: pointer;
                    margin: 0 5px;
                }
            }

            &__lower > p{
                margin: 0px;
                margin-left: 10px;
            }

            &__lower > a{
                text-decoration: underline;
                color: inherit;
            }
        }
    }

    &__copilot-feedback-button{
        display: flex;
        justify-content: center;
        align-items: center;

        &__upvote{
            display: flex;
            justify-content: center;
        }
    }

    &__copilot-feedback-button > *{
        cursor: pointer;
    }

    &__lastMessageAnchor{
        width: 100%;
    }

    &__promptInput{
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-family: "Source Sans Pro";
        font-weight: 400;

        &__cardHeaderPredefinedQuestions{
            display: flex;
            justify-content: flex-end;
            opacity: 1;
            height: 30px;
            transition: opacity 0.3s 0.3s linear, height 0.3s linear;
            overflow: hidden;
            margin: 0 34px 0 34px;

            &__fadeout{
                transition: opacity 0.3s linear, height 0.3s 0.3s linear;
                opacity: 0;
                height: 0px;
            }
        }

        &__cardHeaderPredefinedQuestions > button{
            margin: 3px;
        }

        &__predefinedQuestions{
            display: flex;
            flex-wrap: wrap;
            justify-content: right;
            gap:8px;
            margin: 0 18px 0 18px;
      
            &__customHeaderContainer{
                margin-bottom: 5px;
                width: 100%;

                &__innerContainer{
                    display: flex;
                    justify-content: space-between;

                    &__titlePinContainer{
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        &__toggleIcon{
            margin-right: 8px;
        }

        &__chatIcon{
            margin: 0px 0px 0px 10px;
            cursor: pointer;
            transform: rotate(90deg);
        }

        &__legalQuestionsIcon{
            margin: 0px 10px 0px 0px;
            cursor: pointer;
            display: flex;
            justify-content: center;
        }

        &__mediusAgentLegalQuestionsIconIconContainer{
            margin-top: 10px;
            display: flex;
            justify-content: start;
            align-items: center;
            cursor: pointer;
        }

        &__calloutInnerContainer > p{
            cursor: pointer;
        }

        &__mediusAgentFeedbackIconContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 10px;

            &__copilotTips{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin-top: 10px;
            }

            &__copilotTips > p {
                margin: 0px;
                cursor: pointer;
            }
        }

        &__mediusAgentFeedbackIconContainer > p{
            margin: 0px;
            margin-left: 4px;
            cursor: pointer;
        }

        &__textarea{
            width: 100%;
            resize: none;
            padding: 4px 8px;
            border-radius: $messageBorderRadius;
            border: 1px solid northstar.$md-color-grey-13;
            overflow-y: auto;
            line-height: var(--md-font-lineheight-small);
            height: 16px;
            max-height: 4em; 
        }
    }

    &__systemAlert{
        
    }

    &__aiThinkingWrapper{
        margin-top: 12px;
        border-radius: $messageBorderRadius;
        background-color: $assistantMessageBackgroundColor;
        color: grey;
        width: 40px;
        height: 40px;

        &__dot{
            animation: 1s advance infinite;
            fill: grey;
        }

        &__dot:nth-child(2) { 
            animation-delay: 250ms 
        }

        &__dot:nth-child(3) { 
            animation-delay: 500ms 
        }
    }

    &__processedInvoicesCountTable {
        text-align: left;
        width: 100%;
        th {
            border-bottom: solid 1px northstar.$md-color-grey-100;
            font-size: 14px;
            color: initial;
            line-height: 20px;
        }
    
        tr {
            border-bottom: solid 1px northstar.$md-color-grey-13;
        }
    }

    &__previousInvoicesTable {
        text-align: left;
        width: 100%;
    }
    
    &__followupQuestions{
        &__container{
            width: 100%;
            justify-content: flex-end;
            display: flex;
            flex-wrap: wrap;
        }
        
        &__button{
            margin: 2px;
        }
    }
}

@keyframes advance { 
    10%{ transform: translateY(-2px); }
    90%{ transform: translateY(2px); }
}