.message-dialog {
    &__header {
        display: flex;
    }

    &__header-close-button {
        margin-left: auto;

        &:hover {
            cursor: pointer;
        }
    }

    &__img {
        display: flex;
        justify-content: center;
        padding: 32px 0px 32px 0px;
    }

    &__messages {
        li {
            padding-bottom: 8px;
        }
    }

    &__ok-button-container {
        padding-top: 16px;
    }

    &__ok-button {
        background-color: var(--md-color-brand-primary-base);
        border: 1px var(--md-color-brand-primary-base);
        border-radius: 4px;
        box-sizing: border-box;
        color: white;
        font-weight: 600;
        height: 40px;
        padding: 8px 16px;
    }

    &__ok-button-container {
        display: flex;
        justify-content: center;
    }
}