.inbox-non-fullscreen-container {
    height: 100%;
    background-color: var(--md-color-page-background-empty);
}

#task-view .document-identifier {
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    min-height: 0;
}

.document-identifier-title {
    margin-bottom: 1px;
}

.document-identifier-icons {
    display: flex;
    align-items: center;
}

.document-identifier-icons > div{
    margin: 0px 4px;
}

.copy-to-cart {
    display: flex;
    flex-direction: row;
}

.copy-to-cart #copy-to-cart-btn {
    width: 125px;
    margin-left: auto;
    text-align: right;
}

#inbox-application-view {
    position: relative;
    height: 100%;
    padding-top: 10px;
    box-sizing: border-box;
    background-color: var(--md-color-page-background-empty);
}

#inbox-left #inbox-sidebar {
    width: 185px;
    padding: 0;
    margin: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
}

#inbox-left #inbox-sidebar ul.nav {
    margin-top: 8px;
}

#inbox-left #inbox-sidebar .show-hide-sidebar-btn {
    top: 9px;
}

#inbox-left #inbox-sidebar li.saved-search-item a > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 118px;
    overflow: hidden;
    display: block;
}

#inbox-scroll.scrollable > div {
    padding-right: 5px;
}

#inbox-left > header {
    margin-bottom: 10px;
}

#inbox-left > header > div {
    margin-bottom: 10px;
}

#inbox-left .search-query {
    border-radius: 3px;
    padding: 4px;
    margin-bottom: 0;
    width: 35%;
}

#inbox-left section.group {
    margin-bottom: 35px;
    margin-top: 15px;
}

#inbox-left section.group > header {
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
    position: relative;
    margin-bottom: 15px;
}

#inbox-left section.group > header .btn-toggle-tasks {
    position: absolute;
    right: 0;
    top: 7px;
}

#inbox-left section.group td.actions td.labels {
    text-align: center;
    padding: 2px 0;
}

#inbox-left section.group .labels-dropdowns {
    position: absolute;
    top: 25px;
    left: 38px;
}

#inbox-left section.group .labels-dropdowns.show-bulk-checkboxes {
    left: 73px;
}

#fullscreen-inbox-left.fullscreen #fullscreen-navigation {
    height: 38px;
    margin-bottom: 6px;
}

#fullscreen-inbox-left.fullscreen #fullscreen-navigation > * {
    display: inline-block;
}

#fullscreen-inbox-left.fullscreen #fullscreen-navigation .pagination {
    margin: 0;
}

.fullscreen-inbox-left-content-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.fullscreen-inbox-left-content {
    flex-grow: 1;
}

.fullscreen-custom-document .pagination {
    margin: 0;
}

.fullscreen__tab {
    margin-top: 4px;
}

.fullscreen__tab-content {
    height: 100%;
}

#fullscreen-inbox-resizable-container,
.fullscreen-custom-document {
    height: 100%;
}

#inbox-left .grid-cell.labels > section {
    height: 22px;
}

#inbox-right #history-logs .row-fluid [class*="span"]
#fullscreen-inbox-right #history-logs .row-fluid [class*="span"] {
     min-height: 0px;
}

#inbox-right #history-logs h5,
#inbox-right #history-logs h6,
#fullscreen-inbox-right #history-logs h5,
#fullscreen-inbox-right #history-logs h6 {
     min-height: 0px;
}

#inbox-right #history-logs .detail h6,
#fullscreen-inbox-right #history-logs .detail h6 {
      color: grey;
      font-weight: normal;
}

#inbox-right #history-logs p,
#fullscreen-inbox-right #history-logs p {
      margin: 0;
}

#fullscreen-inbox-right {
    padding-top: 10px;
    padding-right: 20px;
}

.navbar-inverse .nav > li > a:hover {
    background: #555;
}

.navbar-inverse .nav > li > a:focus {
    background: #ccc;
}

.navbar-inverse .nav > li > a:active
.navbar-inverse .nav > li > a:active::after {
    background: #666666;
}

.ui-resizable-helper {
    border-right: 10px solid lightgrey;
    height: 100%;
    background: #ffffff;
    background: rgba(255,255,255,0.4);
}

.resizable-tied, .resizable-app-container {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    float: left;
    width: 50%;
}

.resizable-tied {
    padding-left: 15px;
}

.resizable-app-container{
    padding-right: 15px;
}

.resize-bar {
    position: absolute;
    margin-top: 20%;
}

.resizable-app-container > div.ui-resizable-handle {
    right: -5px;
    width: 10px;
    height: calc(100% - 40px);
    z-index: 9 !important;
}

.resizable-app-container > div.ui-resizable-handle:hover {
    background: lightgrey;
    z-index: 1002; /*in front of the .ui-resizable-icon*/
}

.resizable-app-container > div.ui-resizable-handle:hover > span {
    display: none;
}

.resizable-app-container > .ui-resizable-helper {
     border-left: 1px dotted gray;
}

.resizable-app-container > div.ui-resizable-handle > span {
    background-image: url("images/resize_bar_dots.png");
    background-repeat: no-repeat;
    position: absolute;
    z-index: 1001;
    width: 5px;
    height: 17px;
    margin-top: 150px;
    margin-left: 2px;
}

.ie-indicator-tokens {
    width: 16px;
    height: 16px;
    display: inline-block;
}

.new-inbox-indicator-container {
    position: relative;
    top: 4px;
    display: inline-block;
}

.fullscreen-custom-document-navigation {
    display: flex;
    flex-direction: row;
    clear: both;
}

.fullscreen-custom-document-navigation__left-side {
    flex-grow: 1;
    flex-basis: 0;
}

.fullscreen-custom-document-navigation__right-side {
    flex-grow: 1;
    flex-basis: 0;
}