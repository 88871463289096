.importManagement-masterDataErrors {
    flex-grow: 1;
    overflow: auto;

    &__panel-bar {
        margin: 3px 0;
    }

    &__panel-bar-item {       
        &__header {
            background: #f43a30 !important;
            color: white !important;
        }

        &__content {
            background-color: #f2dede;
            padding: 3px;
            overflow-x: auto;
        }

        &__exception {
            color: #b94a48;   
        }
    }

    &__json-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}