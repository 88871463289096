.forgot-password-page {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    
    &__text {
        color: grey;
        margin-bottom: 10px;
    }
    
    &__errors {
        text-align: center;
        color: red;
        margin-top: 10px;
    }
}

.forgot-password-result-page {
    &__email-sent {
        color: gray;
        text-align: center;
        margin-top: 10px;
    }
}