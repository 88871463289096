.outbox {
    position: relative;
    margin-left: auto;
    margin-right: 12px;
    color: #BBB;
    margin-right: 16px;

    &__badge {
        position: absolute;
        left: 24px;
        top: -2px;
        z-index: 1;
        width: 14px;
        height: 13px;
        border-radius: 50%;
        background-color: orangered;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 11px;
    }

    &__badge-number{
        padding-bottom: 1px;
    }

    &__spinner {
        height: 26px;
        padding-left: 4px;
        padding-right: 4px;
        text-align: center;
        font-size: 10px;
    }

    &__spinner .outbox__rect {
        background-color: rgba(255,255,255,0.87);
        height: 100%;
        width: 3px;
        margin-left: 3px;
        display: inline-block;
        -webkit-animation: outbox-stretchdelay 1.2s infinite ease-in-out;
        animation: outbox-stretchdelay 1.2s infinite ease-in-out;
    }

    &__spinner .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    &__spinner .rect3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }

    &__spinner .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    &__spinner .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }
}

.outbox .glyphicons {
    font-size: 18px;
    display: inline;
}

@-webkit-keyframes outbox-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
    20% { -webkit-transform: scaleY(1.0) }
}

@keyframes outbox-stretchdelay {
    0%, 40%, 100% { 
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }  20% { 
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}