.administration {
    .k-filtercell-operator {
        span.k-dropdown-operator{
            display: none;
        }
    }

    &__grid {
        margin-top: 15px;
        flex: 1 1 auto;
        min-height: 0px;
    }
}
