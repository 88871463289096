@use "sass:color";

.link-selector {
    margin-top: 0;
    margin-left: auto;
    margin-right: 10px;
}

input.link-selector-term {
    padding: 4px 8px;
    margin-bottom: 0;
    font-family: "Source Sans Pro";
    font-size: 13px;
    font-weight: normal;
    line-height: 1;
    border-radius: 2px;
    width: 256px;
    background-color: white;
    color: black;
    border: 1px solid var(--md-color-text-muted);

    &::-webkit-input-placeholder {
        color: grey;
    }

    :-moz-placeholder {
        color: grey;
    }

    :-ms-input-placeholder {
        color: grey;
    }

    &--active {
        background-color: white;
        color: #808080;

        &::-webkit-input-placeholder {
            color: #808080;
        }

        :-moz-placeholder {
            color: #808080;
        }

        :-ms-input-placeholder {
            color: #808080;
        }
    }
}

.link-selector-term__loading-icon {
    background: url('images/icon-loading.gif') no-repeat;
    width: 16px;
    height: 20px;
    position: absolute;
    right: 8px;
    top: 8px;
}

.link-selector-suggestions {
    &.dropdown-menu {
        margin-top: 2px;
        color: #333333;
        padding: 0;
        z-index: 9999999;
        display: block; /* Overrides Bootstrap styling thst hides dropdown lists by default */
        right: inherit;
        border: 1px solid rgba(0,0,0, 0.08);
        border-radius: 3px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }

    &__header {
        background-color: #f6f6f6;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        padding: 6px;
        border-radius: 2px 2px 0px 0px;
        font-size: 12px;
        color: #656565;
    }

    &__list {
        width: 270px;
        border: 0px;
        border-radius: 2px;
        font-size: 12px;
        margin: 0;
        list-style: none;
    }
}

.link-selector-suggestion {
    padding: 6px;
    cursor: pointer;
    line-height: 17px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    $selected-suggestion-background: #D1F1F8;

    &.selected {
        background: $selected-suggestion-background;

        &:hover {
            background: color.adjust($selected-suggestion-background, $lightness: -10%);
        }
    }

    &:last-child {
        border-bottom: none;
        border-radius: 0px 0px 2px 2px;
    }

    &:hover {
        background: #ebebeb;
    }

    &__position {
        color: #696969;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &--emphasized {
            color: #0f0f0f;
            font-weight: 600;
        }
    }

    &__highlighted {
        background-color: yellow;
    }
}

.link-selector-no-results {
    margin-top: 3px;
    border-radius: 2px;

    &.dropdown-menu {
        width: 270px;
        display: block;
        border: 0;
        margin-left: 1px;
        font-size: 11px;
        right: inherit;
    }

    &__item {
        display: block;
        font-weight: bold;
        padding: 3px 3px 3px 13px;
    }
}

