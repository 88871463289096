.display-component {
}

.presenters-text-default {
}

.presenters-text-maxlength {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.presenters-labels {
    overflow: visible;
    position: relative;
    display: block;
    height: 19px;
}

.presenters-labels .label-description {
    margin-left: 5px;
    text-shadow: none;
    display: inline;
    margin-left: 20px;
}

.presenters-labels .label {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    display: inline-block;
    text-shadow: none;
}

.multiple-label1 
{
    position: absolute;
    width: 45px;
    z-index: 4;
}

.multiple-label2 
{
    position: absolute;
    width: 45px;
    top: 2px;
    left: 2px;
    z-index: 3;
}

.multiple-label3 
{
    position: absolute;
    width: 45px;
    top: 4px;
    left: 4px;
    z-index: 2;
}

.multiple-label-has-more {
    position: absolute;
    top: 6px;
    left: 6px;
    z-index: 1;
    width: 57px;
    text-align: right;
}

.presenters-labels .label .height-90{
    height: 90%;
}

.presenters-labels .streach-width {
    width: auto;
    display: inline;
}