.inbox-search {
    background: #ffffff;
    position: absolute; 
    top: 40px; 
    z-index: 10000; 
    left: 4%;
    border: 1px solid #eee;
    box-shadow: 3px 3px 7px #2f4f4f;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}


.inbox-search .chosen-container {
    display: block;
    width: 100% !important;
}

.inbox-search .chosen-container-multi .chosen-choices {
    border: 1px solid #ccc;
    border-radius: 3px;
}