.configuration-label {
    overflow: hidden;
    margin-bottom: 5px;
}

.configuration-control {
    margin-bottom: 10px
}

.custom-fields-with-list {
    &__non-editable-area{
        
        &:focus{
            outline: none;
            outline-width: 0;
            border-color: #ccc;
            box-shadow: inset 0px 1px 1px rgba(0,0,0,0.075);    
        }
    }
}