.links-sections-shared {
    margin-top: 10px;
    overflow: auto;
}

.historical-shared {
    border-color: var(--md-color-grey-13);
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    padding: 5px;
}

.genericLinks__dataTableCard-historical {
    @extend .historical-shared;
}

.duplicatedLinks__dataTableCard-historical {
    @extend .historical-shared;
}

.referencedLinks__dataTableCard-historical {
    @extend .historical-shared;
}

.noLinksSpecified {
    &__container {
        margin: auto;
        width: 50%;
        padding: 50px;

    }
    &__iconInfo {
        width: 30px;
    }
    &__header {
        font-size: 20px;
        margin-left: 8px;
    }
    &__link {
        margin-top: 5px;
    }
    &__mainText {
        margin-bottom: 10px;
        margin-left: 2px;
    }
    &__headerContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        align-items: center;
        font-weight: bold;
    }
}

.linksWithSections {
    &_mainContainer {
        margin-top: 10px;
        thead {
            color: black;
        }
        th {
            color: black;
            border-left-width: 0px;
        }
        td {
            border-left-width: 0px;
        }
    }
    &_historicalContainer {
        margin-top: 15px;
    }

}

.duplicatedLinks__mainContainer {
    @extend .links-sections-shared;
}

.referencedLinks {
    &__mainContainer {
        @extend .links-sections-shared;
    }
    &__companyColumn {
        min-width: 100px;
        display: block;
        overflow-wrap: break-word;
    }
    &__supplierColumn {
        min-width: 100px;
        display: block;
        overflow-wrap: break-word;
    }
}

.genericLinks__mainContainer {
    @extend .links-sections-shared;
}