#send-for-review-popup-tpl,
#put-on-hold-popup-tpl {
    width: 400px;
}

#send-for-review-popup-tpl .review-comment-input,
#put-on-hold-popup-tpl .on-hold-reason-input {
    height: 160px;
}

#send-for-review-popup-tpl .controls.review-comment-container,
#put-on-hold-popup-tpl .controls.on-hold-reason-container{
    width: 350px;
}

#send-for-review-popup-tpl .edit-component,
#put-on-hold-popup-tpl .edit-component{
    width: 100%;
}

#send-for-review-popup-tpl .edit-autocompleter-user-container{
    display: inline-block;
    padding-right: 11px;
    width: 300px;
}

#send-for-review-popup-tpl .reviewers-autocompleter {
    width: 300px;
}