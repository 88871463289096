#document-search {
    height: 100%;
    position: relative;
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
}

.nav-list .divider {
    clear: both;
}

.progress-thin
{
    margin-top: 5px;
    margin-bottom: 0;
    height: 10px;
}

#document-search .row-margin-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

#document-search .box-padding-2 {
    padding: 2px;
}

#document-search .margin-5 {
    margin: 5px;
}

#document-search .margin-0 {
    margin: 0px;
}

#document-search .margin-left-10 {
    margin-left: 10px;
}

#document-search section#results {
    margin-top: 15px;
}

body.dragging, body.dragging * {
  cursor: move !important;
}

.dragged {
  position: absolute;
  opacity: 0.5;
  z-index: 2000;
}

#document-search-columns-modal ol.example li.placeholder {
  position: relative;
  /** More li styles **/
}
#document-search-columns-modal ol.example li.placeholder:before {
  position: absolute;
  /** Define arrowhead **/
}

#document-search-columns-modal .sortable li {
    height: 30px;
}

#document-search-columns-modal .modal-body {
    overflow: visible;
}

.column-config-tmpl .sortable li > label {
    display: inline-block;
    max-width: 80%;
    margin: 0;
    padding-left: 20px;
    vertical-align: middle;
    margin-right: 5px;
}

.column-config-tmpl .sub-header > div {
    display: inline-block;
}

#document-search-columns-modal .sortable li:hover {
    cursor: move;
}

#document-search-columns-modal .sortable li.ui-sortable-helper{
    cursor: move;
}

#document-search-columns-modal > article > section {
    overflow: auto;
    height: 200px
}

#document-search .operator-caret {
    position: absolute;
    top: 5px;
    right: 4px;
}

#document-search .operator-text {
    overflow: hidden;
    text-overflow: ellipsis;
}

#document-search .filter-value-editor {
    display: inline-block;
    font-size: 14px;
    max-width: 120px;
}

#document-search .column-filter-editor .filter-value-editor .edit-component > .input-append,
#document-search .column-filter-editor .filter-value-editor .edit-component > .input-prepend {
    margin-bottom: 0;
}

#document-search .column-filter-editor .filter-value-editor:last-child input[type=text],
#document-search .column-filter-editor .filter-value-editor:last-child {
    border-radius: 0 4px 4px 0;
    vertical-align: middle;
}

#document-search .column-filter-editor .filter-value-editor:last-child .input-append input[type=text] {
    border-radius: 0;
    vertical-align: middle;
}

#document-search .column-filter-editor .input-append,
#document-search .column-filter-editor .input-prepend {
    vertical-align: top;
}

#document-search aside.saved-query-side-menu {
    min-height: 20px;
    padding: 5px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

#document-search .btn-side-menu {
    padding-left: 13px;
}

#document-search aside.saved-query-side-menu section {
     margin-bottom: 20px;
}

#document-search aside.saved-query-side-menu .saved-queries-header {
    cursor: pointer;
}

#document-search #query-box {
    margin: 10px 0;
    position: relative;
}

#document-search #query-box button:first-child {
    display: block;
    margin-bottom: 5px;
}

#document-search #additional-filtering {
    border: 1px solid #E5E5E5;
    border-left: 0;
    border-right: 0;
}

#additional-filtering input[type=text] {
     margin-bottom: 0;
}

.column-filter-editor {
    display: inline-block;
    margin: 10px;
}

.column-filter-editor a {
    min-height: 20px;
}

#document-search #save-as-dropdown {
    background: #ffffff;
    position: absolute;
    top: 37px;
    z-index: 900;
    right: 0;
    width: 200px;
    border: 1px solid #eee;
    box-shadow: 3px 3px 7px #2f4f4f;
    border-radius: 5px;
}

#document-search #save-as-dropdown > header {
    position: relative;
    border-bottom: 1px solid #eee;
    height: 20px;
}

#document-search #save-as-dropdown > header > a {
    display: block;
    position: absolute;
    right: 10px;
    top: 2px;
}

#document-search #save-as-dropdown > section {
    padding: 10px;
    overflow: auto;
    /*height: 100px;*/
}

#document-search #save-as-dropdown > nav {
    background: #f5f5f5;
    height: 35px;
    padding: 10px 5px 5px;
}

#document-search #save-as-dropdown > nav button {
    margin: 0;
}

#document-search #save-as-dropdown > section input[type=text] {
    margin-bottom: 0;
}

#document-search #save-as-container {
    position: relative;
    margin-left: 5px;
}

#document-search #save-as-container button.open {
    background-color: #E6E6E6;
    background-image: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
}

#document-search .saved-query-side-menu .open > .dropdown-menu {
    width: 90%;
}

#document-search .query-items-menu div.query-item .item-operator a {
    display: none;
}

#document-search .query-items-menu div.query-item:hover .item-operator a {
    display: block;
}

#document-search .query-items-menu div.query-item:hover .item-operator div {
    cursor: pointer;
}

#document-search .query-items-menu div.query-item .item-operator {
    margin-right: 3px;
    float: right;
}

.column-config-tmpl div.pull-left {
    margin-right: 5px;
}

#document-search .saved-query-side-menu .nav-pills > li > a {
    margin-right: 2px;
    padding: 4px;
}

#document-search-columns-modal hr {
    margin-top: 10px;
    margin-bottom: 10px;
}

#document-search td {
    vertical-align: middle;
}

#document-search .inner-collection {
    padding: 0;
    min-height: 26px;
}

#document-search .inner-collection ul {
    border: 0;
    margin: 0;
    padding: 0;
    list-style: none;
}

#document-search .inner-collection ul > li {
    padding: 4px 5px;
    line-height: 18px;
    text-align: left;
    border-bottom: 1px solid #DDDDDD;
    display: block;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-height: 18px;
}

#document-search .inner-collection ul > li span.display-component *:empty {
    display: none;
}

#document-search .inner-collection ul > li:last-child {
    border-bottom: 0;
}

#document-search .saved-query-side-menu .nav-list > li > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

#document-search .medius-grid table th span.column-name span {
     display: inline-block;
}

#document-search .medius-grid table th span.column-name i {
    vertical-align: top;
    margin-left: 5px;
}

#document-search #additional-filtering > select {
    width: 100px;
}

#document-search .tab-content {
    margin-left: 0px;
    margin-right: 0px;
    margin-top:0px;
    margin-bottom: 0px;
}

.info-reports {
    color: #fff;
    padding: 8px;
    background-color: #545a60;
    margin-bottom: 10px;
    border-radius: 2px;
 }

.info-reports a {
    color: #17b3de;
}
