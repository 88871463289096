.custom-pnotify-stack-bar-bottom {
    width: 60%;
    padding: 5px 25px 5px 5px;
    font-size: 16px;
    white-space: normal;
}

.custom-pnotify-current-date {
    font-size: 13px;
    position: absolute;
    bottom: 0px;
    right: 15px;
    text-align: right;
}

.custom-pnotify-icon.glyphicons:before {
    display: inline-block;
    padding: 2px 4px
}

.ui-pnotify.ui-pnotify-with-icon.custom-pnotify-stack-bar-bottom .ui-pnotify-title,
.ui-pnotify.ui-pnotify-with-icon.custom-pnotify-stack-bar-bottom .ui-pnotify-text {
    margin-left: 30px;
}

.custom-pnotify-message .ws-normal {
    white-space: normal;
}

.custom-pnotify-message .ws-pre-line {
    white-space: pre-line;
}

.custom-pnotify-message .no-left-margin-text {
    margin-left: 0px;
}

.custom-pnotify-message .center-align-icon {
    text-align: center;
}

.custom-pnotify-message {
    background-color: #323232;
    border: 0 solid #323232;
    color: white;
}


/*
 *  STYLE OF SCROLL
 */
 .custom-pnotify-large-error > input, .custom-pnotify-large-error > textarea {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: #323232;
    color: white;
    border: none;
}

.custom-pnotify-large-error {
    overflow: hidden;
    position: relative;
    height: 100px;
}

.custom-pnotify-large-error > textarea {
    height: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.custom-pnotify-large-error > textarea:focus {
    outline-offset: 0;
    outline-style: none;
}

.custom-pnotify-large-error-hide-scroll {
    position: absolute;
    width: 17px;
    top: 0;
    right: 0px;
    height: 102px;
    border-left: 2px solid lightgray;
    background-color: #323232;
}
