@use './sharedStyles/prepayments.scss' as prepayments;

$card-label-color: rgb(51, 51, 51);

.applied-prepayments-information-table {
    &__container {
        @extend %data-table-base
    }
}

.prepayment-card {
    h2 {
        color: $card-label-color;
    }

    &__apply-prepayments-button {
        margin-top: 8px;
        margin-bottom: 8px;
    }
}

//TODO: show it when implementing behavior for apply prepayments button
.apply-prepayment-button {
    margin-bottom: 10px;
    display: none;
}

//TODO: this class can be removed when implementing behavior for edit and remove buttons 
.action-buttons-container {
    display: none;
}

.applied_prepayments_modal {
    &__total-validation-failed {
        color: red;
    }

    &__custom-cell-alignment {
        display: flex;
        justify-content: right;
        width: 100%
    }

    &__validation-notification {
        margin-top: 20px;
    }
}

.prepayment-applications-modal {
    &__loader-overlay {
        position: fixed;
        top: 0px;
        left: 0px;
        height:100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        background-color: rgba(0,0,0, 0.25);
    }
    
    &__prepayment_data_table {
        margin-bottom: 24px;
        max-height: 60vh;
        overflow: auto;
    }
}