$blue-100: #0e70c6;

$dark-grey-16: #dbddde;

.supplierContract {
    &__grid {
        height: 500px;
        a {
            color: #08c;
            text-decoration: none;

            &:hover {
                color: #005580;
                text-decoration: underline;
            }
        }
    }
    &__mainContainer {
        display: flex;
        flex-direction: column;
        &__inactiveFilter {
            margin-top:5px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
            input {
                margin-top: 0px;
            }
            label {
                margin-left:4px;
                margin-bottom: 0px;
            }
        }
    }
    &__reclassifyContainer {
        width: 8em;
        align-self: end;
        margin-top: 10px;
    }
    &__grid {
        th.k-header {
            user-select: none;
            touch-action: none;
        }
    }
}

.reclassify-confirmation {
    margin: 0px 0px 0px 8px;
    padding: 8px 16px;
    border: 1px $blue-100;
    border-radius: 4px;
    background-color: $blue-100;
    color: white;
    &:hover {
        cursor: pointer;
        background-color: $blue-100;
        border: 1px $blue-100;
    }
}

.request-reclassify-dialog {
    display: flex;
    flex-direction: column;

    .k-widget.k-window.k-dialog {
        height: 450px;
    }

    &__container {
        display: flex;
        flex-direction: column
    }

    &__buttons-container {
        display: flex; 
        justify-content: flex-end;
        padding-top: 16px;
    }

    &__cancelButton {
        height: 100%;
    }

    &__clear-form-button {
        height: 40px;
        margin-right: 8px;
    }    

    &__add-button {
        height: 40px;
    }

    &__header {
        display: flex;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;        
    }
 
    &__header-close-button {
        margin-left: auto;

        &:hover {
            cursor: pointer;
        }
    }

    &__line-divider {
        border-bottom: 1px solid $dark-grey-16;
        height: 1px;
    }

    &__heading-line-divider {
        padding-top: 16px;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    &__content {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    &__content-details {
        margin-top: 10px;
    }
}



.dateTimeCell {
    width: auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

$inaccessibleIndicatorMargin: 5px; 
.linkCell {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .linkCell_link {
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .linkCell_text {
        
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .linkCell_locker {
        margin-left: $inaccessibleIndicatorMargin;
        min-width: 16px;
    }
}

.supplier-autocompleter {
    .k-virtual-content {
        .k-list-ul {
            float: left;
            width: 100%;
        } 
    }
}