.administration-action-buttons {
    display: flex;
    justify-content: space-between;
    min-height: 40px;
    align-items: center;

    &__save-button {
        margin-right: 10px;
    }

    &__delete-button.k-button {
        background: #f43a30;
        color: white;
        margin-left: auto;

        &:hover {
            background: #f43a30;
            color: white;
        }
    }

    .disabledWithTitle {
        pointer-events:auto !important
    }
}
