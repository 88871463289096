.added-reason-code {
    &__list {
        border-style: none;

        &__item {
            height: 30px;
            padding: 3px;
            display: flex;
            justify-content: left;
            align-items: center;

            span {
                margin: 0.1em;
            }
        }
    }

    &__code,
    &__creator {
        font-weight: bold;
    }
}