.home-realm-discovery-page {
    &__question {
        margin-top: 23px;
        margin-bottom: 3px;
        font-size: 16px;
    }

    &__button-selection {
        margin: 0 !important;
        margin-top: 3px;
    }

    &__checkbox-container {
        margin-top: 23px;

        &__label {
            position: relative;
            padding-top: 3px;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 16px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            text-align: left;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
        }
        
        &__checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            border: solid 1.3px rgba(0, 0, 0, 0.59);
            border-radius: 3px;

            &:after {
                content: "";
                position: absolute;
                display: none;
            }
        }
        
        &__label:hover input ~ &__checkmark {
            background-color: #ccc;
        }
        
        &__label input:checked ~ &__checkmark {
            background-color: rgb(51, 159, 255);
        }
        
        &__label input:checked ~ &__checkmark:after {
            display: block;
        }
        
        &__label &__checkmark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}