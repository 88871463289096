.authorizationGroups {
    .k-filtercell-operator {
        span.k-dropdown-operator {
            display: none;
        }
    }

    &__grid {
        margin-top: 10px;
        flex: 1 1 auto;
        min-height: 0px;
        & tr {
            cursor: pointer;
        }
    }

    .k-pager-info {
        display: flex!important;
    }
}
