.inbox-search-body-scrollable {
    overflow: auto;
    max-height: 66vh;
}

.inbox-search-body {
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow: unset;
    max-width: 800px;
    margin-left: 0.3em;


    &__loader {
        width: 100%;
        height: 100%;
    }

    &__half-section {
        width: 50%;
        margin-right: 5px;

        input[type="radio"] {
            width: auto;
        }
    }
    
    &__full-section {
        display: flex;
        flex: none;
        flex-direction: row;
        width: 100%;
        margin-right: 0px;
    }

    &__separator {
        margin: 5px 0 5px 0;
        border-top: 1px solid #eee; 
        border-bottom: 1px solid #fff;
        width: 100%;
    }

    &__select-container {
        max-width:300px;
    }

    &__options {
        display: flex;
        flex-shrink: 0;
    }

    &__labels {
        display: flex;
        flex-shrink: 0;
        margin-top: 5px;
        align-items: center;
    }

    &__section-name {
        margin-top: 10px;
    }

    &__document-fields {
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
    }

    &__document-field {
        width: 33.3%;
        padding: 0 5px 0 5px;
        box-sizing: border-box;
    } 
    
    &__document-full-field {
        width: 100%;
        padding: 0 5px 0 5px;
        box-sizing: border-box;
    }

    &__document-field-name {
        margin-top: 5px;
    }

    &__checkbox-container {
        min-height: 20px;
        padding-left: 20px;
    }

    &__checkbox-container input[type="checkbox"] {
        float: left;
        margin-left: -20px;
    }

    &__checkbox-tooltip {
        float: right;
    }

     &__checkbox-container--with-tooltip {
        float: left;
    }

    &__checkbox-text {
        margin-top: 4px;
    }

    &__checkboxes-list {
        list-style-type: none;
    }
}

/*
    Chosen library overrides
*/

.chosen-container .chosen-choices .search-field {
    width: 100%;
}

.chosen-container .chosen-choices .search-field input {
    width: 100% !important;
}
