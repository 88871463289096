.wizard > .modal-body {
    min-height: 140px;
    position: static;
}

.wizard > .modal-body > .screen > h1 {
    font-size: 1.6em;
    margin-bottom: 10px;
}

.wizard .label-disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.wizard ul.steps {
    background: #f9f9f9;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    overflow: hidden;
    width: 99.999%;
}

.wizard ul.steps > li {
    color: #999999;
    display: block;
    float: left;
    font-size: 14px;
    height: 35px;
    line-height: 35px;
    padding: 0 13px 0 22px;
    position: relative;
    margin: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.wizard ul.steps.steps-1 > li {
    width: 99.999%;
}

.wizard ul.steps.steps-2 > li {
    width: calc(99.999% / 2);
}

.wizard ul.steps.steps-3 > li {
    width: calc(99.999% / 3);
}

.wizard ul.steps.steps-4 > li {
    width: calc(99.999% / 4);
}

.wizard ul.steps.steps-5 > li {
    width: calc(99.999% / 5);
}

.wizard ul.steps > li:first-child {
    padding-left: 12px;
}

.wizard ul.steps > li > div.text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.wizard ul.steps > li.active {
    background: #d9edf7;
    color: #3A87AD;
}

.wizard ul.steps > li.done {
    background: white;
    color: black;
}

.wizard ul.steps > li.done .label {
    background-color: #3A87AD;
    color: white;
}

.wizard ul.steps > li.done .arrow:before {
    border-left-color: white;
}

.wizard ul.steps > li .arrow,
.wizard ul.steps > li .arrow:before {
    display: block;
    position: absolute;
    border-style: solid none solid solid;
    border-color: transparent transparent transparent #d0d0d0;
    border-width: 17px 0 18px 15px;
    width: 0;
    height: 0;
    top: 0;
    right: -14px;
    content: "";
    z-index: 1;
}

.wizard ul.steps > li .arrow:before {
    border-left: 15px solid #f9f9f9;
    top: -17px;
    right: 1px;
    z-index: 0;
}

.wizard ul.steps > li.active .arrow:before {
    border-left-color: #d9edf7;
}

.wizard .notification-information > li {
    color: gray;
    font-size:13px;
}

.wizard ul.steps > li:last-child > .arrow {
    display: none;
}

.wizard .subject-row {
    margin-bottom: 10px;
}

.wizard .step-result h4 {
    cursor: pointer;
}

.wizard .step-result .label {
    position: relative;
    top: -2px;
}

.wizard .step-result h4.successes {
    color: #468847;
}

.wizard .step-result h4.failures {
    color: #b94a48;
}

.wizard .row-margin-bottom {
    margin-bottom: 5px;
}