#document-container
{
    height: 100%;
    position: relative;
}

#document-container > div,
#document-container > div > article
{
    position: relative;
}

.full-height
{
    height: 100%;
}

.hash-file-name
{
    float: left;
    margin-right: 5px;
    padding: 3px;
}

.hash-file-selector > label {
    padding: 3px;
    border-bottom: 1px solid #dddddd;
    display: block;
    cursor: auto;
}

.hash-file-selector {
    height: 75px;
}

.hash-controls {
    display: block;
}

div#document-hash-files-grid
{
    clear: both;
}

div#document-hash-file-adder
{
    margin-bottom: 15px;
}

#document-display-container {
    width: 100%;
    margin: 0;
    padding: 0;
}

#document-hash-files
{
    height: 100%;
    position: relative;
    width: 100%;
}

.image-normal
{
    height: 550px;
}

.image-full
{
    top: 0;
}

#save-draft-modal
{
    min-width: 300px;
    width: 350px;
    margin-left: -190px;
}

#document-creator-settings
{
    position: fixed;
    bottom: 0;
    right: 20px;
}

#document-creator-settings span.separator
{
    border-right: 1px solid #000000;
    display: inline-block;
    height: 24px;
    margin: 0 8px -9px;
    width: 0;
}

.halflings-info-sign{
    margin-left:2px;
}

/*z-index dependencies: #administration #administration-sidebar -> #create-menu ->  #modal-scrollable,*/
#create-menu
{
    z-index: 1050;
}

.creator-header
{
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
}

#register-document-editor
{
    overflow-x: hidden;
}

.document-left-side-tab {
    height: 100%;
}
