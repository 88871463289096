.sidebar {
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__grid {
        width: 450px;
        margin-bottom: 10px;

        &__input {
            width: 100%;
            font-size: 12px !important;
        }
        
        tr {
            cursor: pointer;
            font-size: 12px;
            
            td {
                padding: 3px 12px 3px 12px;
            }
        }

        .k-grid-pager {
            font-size: 12px;
        }
    }
}