.comment__presentation_container {
    white-space: pre-wrap;
}

.comment__mention {
    color: var(--md-color-brand-primary-base);
    display: inline;
}

.comment__mentionTooltip {
   display: block;
    padding-right: 30px;
    padding-left: 10px;
    font-family: "Source Sans Pro";
    font-size: 14px;
    line-height: 20px;
    text-align: left;
}

 .comment__mentionTooltip.description {
     color: #777;
 }

  .comment__mentionTooltip.title {
      font-weight: bold;
 }