.risk-factor-icon-container{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 3px;
    padding: 5px 5px 2px 6px;
    cursor: pointer;
    width: 14px;
    height: 18px;

    &__moreThanOneContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 28px;
    }

    &__kendoCalloutStyle{
        overflow-x: hidden !important;
    }

    &__moreThanOneContainer > p{
        margin: 0px 0px 0px 3px;
        font-weight: 550;
        color: red;
    }

    &__header{
        margin-bottom: 10px;
    }

    &__text{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    &__text > p{
        margin: 0px;
        padding: 0px;
    }

    &__text:last-child{
        margin-left: 5px;
    }

    &__bottom{
        margin: 0px;
    }

    &__calloutContainer{
        width: 540px;

        &__bannerSeparator{
            margin: 8px -20px;
            border-top: 2px solid rgba(1, 1, 1, 0.11);
        }
    }

    &__cardH2{
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-top: 7px;
        margin: 0px;
    }

    &__topNoticeContainer{
        background-color: #F3F7FB;
        width: 510px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        border-radius: 5px;

        &__iconTextWrap{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__iconTextWrap > p{
            font-size: 20px;
            font-weight: 600;
            margin: 0px 0px 0px 3px;
            padding: 0px;
        }
    }

    &__cardWrapper{
        border: 1px solid #dedede
    }

    &__cardWrapper > p{
        width: 100%;
        border: 3px dashed black;
    }

    &__topNoticeContainer{
        font-size: 16px;
    }

    &__cardInternalContainer{
        width: 500px;
    }

    &__cardInternalContainer > h4{
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
    }

    &__cardInternalContainer > p{
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }

    &__handlerDetails{
        display: flex;
        font-size: 12px;
        width: 100%;
        align-items: center;
        justify-content: start;
    }
    
    &__handlerDetails > p{
        margin: 0px 0px 0px 5px;
        font-weight: 400;
        line-height: 24px;
        color: #333333;
    }

    &__handlerDetails:first-child{
        margin: 0px;
    }

    &__numberLabel{
        background-color: #F9CCCF;
        padding: 2px 4px;
        width: fit-content;
        border-radius: 4px;
        margin: 3px;
    }

    &__bannerContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;

        h2{
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
        
    }
    
    &__closeIconContainer{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}