.comment__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.comment__body {
    display: flex;
    justify-content: space-between;
}

.comment__content {
   width: 90%;
}

.comment__button {
    padding-left: 10px;
}