.importManagement-importLogs {
    height: 100%;
    width: 100%;
    overflow-y: auto;

    &__card {
        width: 100%;
        margin-top: 10px;
        box-sizing: border-box;
    }

    &__card-title {
        margin-bottom: 4px;
        font-size: 14px;
    }

    &__card-footer {
        text-align: right;
    }
}
