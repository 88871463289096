#payment-term-editor {
    .payment-term-lines {
        margin-top: 20px;
    }

    .payment-term-lines table .trash-cell {
        text-align: center;
    }

    section header {
        margin-bottom: 10px;
        margin-top: 25px;
        font-size: 20px;
        border-bottom: 1px solid #ddd;
    }
}
