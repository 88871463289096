.documentCacheManagement {
    padding: 0 20px;
    height: 100%;
    display: flex;

    &__progressBar {
        margin-top: 20px;

        &__border {
            border: 1px solid #17BADE !important;
            margin-top: 20px;
            width: 500px;
        }

        &__bar {
            color: #000 !important;
            background-color: #17BADE !important;
        }
    }

    &__gridPanel {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    &__toolbar-label {
        margin: 0 10px;
    }

    &__documentsGrid {
        font-size: 12px;
        overflow-y: hidden;
        cursor: pointer;
    }
}