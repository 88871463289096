$border-left-width: 0px;
$table-color: black;
@mixin overflow-style {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

%data-table-base {
    thead {
        color: $table-color;
    }
    th {
        color: $table-color;
        border-left-width: $border-left-width;
    }
    td {
        border-left-width: $border-left-width;
        a {
            @include overflow-style;
        }
        .oveflow-ellipsis-container {
            @include overflow-style;
        }
    }
}